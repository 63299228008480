import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Children, useState } from "react";
import { endpoints } from "../constants/Endpoints";
import { instance } from "../services/https/inceptors";
import "../styles/inputs.scss";
import { Button } from "./buttons";

const Input = ({
  id,
  type,
  value,
  placeholder,
  className,
  disabled,
  onChange,
  children,
  label,
  returnEvent,
}) => (
  <div className={className}>
    <label className="mb-1 font-semibold">{label}</label>
    <div className="form-group mb-3">
      {children && (
        <label htmlFor={id} className="form-label">
          {children}
        </label>
      )}

      <input
        className="form-control"
        placeholder={placeholder}
        type={type}
        id={id}
        value={value}
        disabled={disabled}
        onChange={(event) => {
          if (returnEvent) onChange(event);
          else onChange(event.target.value);
        }}
      />
    </div>
  </div>
);

Input.defaultProps = {
  type: "text",
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "number"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.string,
};

/**
 * select
 */

const Select = ({
  key,
  label,
  value,
  onChange,
  disabled,
  returnEvent,
  vertical,
  children,
  className = "",
}) => (
  <div className={className}>
    {label && <label className="mb-1 font-semibold">{label}</label>}
    <div className={classnames("mb-3", !vertical && "form-group")}>
      <select
        key={key}
        disabled={disabled}
        className="form-select"
        style={{ border: `1px solid rgb(198, 215, 201)` }}
        value={value}
        onChange={(event) => {
          if (returnEvent) onChange(event);
          else onChange(event.target.value);
        }}
      >
        <option value="">Select Option</option>
        {children}
      </select>
    </div>
  </div>
);

Select.defaultProps = {
  vertical: false,
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  vertical: PropTypes.bool,
  children: PropTypes.node,
};

/**
 * check input
 * used for creating checkbox or radio button
 */

const Check = ({ type, name, className, value, children }) => (
  <div className={`form-check ${className}`}>
    <input
      className="form-check-input"
      type={type}
      name={name}
      id={value}
      value={value}
    />

    <label className="form-check-label" htmlFor={value}>
      {children}
    </label>
  </div>
);

Check.defaultProps = {
  type: "checkbox",
  className: "mb-3",
};

Check.propTypes = {
  type: PropTypes.oneOf(["checkbox", "radio"]),
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  // children: PropTypes.object,
};

/**
 * Radio Button
 */

const RadioGroup = ({ label, name, inline, onChange, children }) => (
  <div className={classnames(inline && "mb-3")}>
    {label && <label className="form-label">{label}</label>}

    <div onChange={(event) => onChange(event.target.value)}>
      {Children.map(children, (child) => {
        if (child.type.name === Check.name) {
          return (
            <Check
              {...child.props}
              name={name}
              className={classnames(inline ? "form-check-inline" : "mb-3")}
              type="radio"
            />
          );
        }
      })}
    </div>
  </div>
);

RadioGroup.Button = Check;

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  child: PropTypes.node,
};

/**
 * time input which returns time in minutes
 */

const Time = ({ value, onChange, label }) => {
  const hours = Math.trunc(value / 60);
  const minutes = value % 60;

  const [active, setActive] = useState("hours");

  function onActive(value) {
    if (value !== active) setActive(value);
  }

  function decrement() {
    if (active === "hours") {
      if (hours > 0) onChange((previous) => previous - 60);
    } else {
      if (minutes > 0) onChange((previous) => previous - 1);
    }
  }

  function increment() {
    if (active === "hours") {
      if (hours < 24) onChange((previous) => previous + 60);
    } else {
      if (minutes < 59) onChange((previous) => previous + 1);
    }
  }

  return (
    <div className="!w-52">
      <label className="mb-2 font-semibold">{label}</label>
      <div className="form-group mb-3">
        <div className="time form-control">
          <div
            className={classnames("indicator", active === "hours" && "active")}
            onClick={() => onActive("hours")}
          >
            {hours.toString().length < 2 ? `0${hours}` : hours}
          </div>

          <span className="mx-2">:</span>

          <div
            className={classnames(
              "indicator",
              active === "minutes" && "active"
            )}
            onClick={() => onActive("minutes")}
          >
            {minutes.toString().length < 2 ? `0${minutes}` : minutes}
          </div>

          <span className="ms-2">hrs</span>

          <span className="spacer"></span>

          <span className="button" onClick={decrement}>
            &#x025C2;
          </span>

          <span className="button" onClick={increment}>
            &#x025B8;
          </span>
        </div>
      </div>
    </div>
  );
};

Time.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

/**
 * Date
 */

const Date = ({ value, onChange, label }) => (
  <div className="form-group mb-3">
    <label className="form-label">{label}</label>
    <input
      type="date"
      className="form-control"
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
);

Date.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

/**
 * File input. Used for uploading images
 */

const Image = ({ className, onUpload, directory }) => {
  if (directory === undefined) {
    return;
  }

  const onChange = async (target) => {
    let formData = new FormData();
    formData.append("file", target.files[0]);
    formData.append("directory", directory);

    const res = await instance.post(endpoints.uploadToS3, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    onUpload(res?.data);
  };

  return (
    <label className={classnames("file-label", className)}>
      <Button className="square">
        <i className="bi bi-paperclip" style={{ fontSize: "12px" }}></i>
      </Button>

      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={(event) => onChange(event.target)}
      />
    </label>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  onError: PropTypes.func,
  onUpload: PropTypes.func,
};

/**
 * Textarea
 */

const Textarea = ({
  id,
  value,
  placeholder,
  className,
  onChange,
  label,
  rows,
}) => (
  <div className={className}>
    <label className="mb-1 font-semibold">{label}</label>
    <div className="form-group mb-3">
      <textarea
        className="form-control"
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        rows={rows}
      />
    </div>
  </div>
);

Textarea.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.number,
};

export { Date, Image, Input, RadioGroup, Select, Textarea, Time };
