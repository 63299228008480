import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Markup } from "../../components/displays";
import { authUser } from "../../providers/index";
import { Admin } from "../../utilities/constants";
import { getLanguageName } from "../../utilities/helpers";
import { Button } from "../buttons";
import "./style.scss";

const QuestionBox = ({
  question,
  index,
  length,
  language,
  toggleReview,
  togglePublish,
  deleteQuestion,
}) => {
  const { role } = useRecoilValue(authUser);

  const { push } = useHistory();

  return (
    <div
      key={index}
      style={{ width: "100%" }}
      className={classnames(
        "border !border-gray-400 rounded p-3 relative",
        index !== length - 1 && "mb-3"
      )}
    >
      <div className="d-flex gap-4">
        <div className="col">
          <div className="d-flex gap-3 mb-5">
            <div className="w-50">
              <p className="text-break" style={{ whiteSpace: "pre-line" }}>
                <span
                  dangerouslySetInnerHTML={{ __html: question.text[language] }}
                />
              </p>
            </div>

            <div className="border-end"></div>

            <div className="w-50">
              <Markup latex={question.text[language]} />
            </div>
          </div>

          <div className="row view-questions-data">
            <div className="col">
              <div className="font-semibold"> Subject:</div>
              <div> {question?.subject?.title}</div>
            </div>
            <div className="col question-box-answer">
              <div className="font-semibold">Answer:</div>
              {question.choices && question?.choices?.length > 0 ? (
                <ol type="a">
                  {question.choices.map((choice, index) => (
                    <li
                      key={index}
                      className={`choice ${
                        choice.answer ? "correct-answer" : ""
                      }`}
                    >
                      <Markup
                        latex={
                          choice.text[language]?.length
                            ? choice.text[language]
                            : "N/A"
                        }
                        className="mb-0"
                      />
                    </li>
                  ))}
                </ol>
              ) : (
                <Markup
                  latex={
                    question.answer[language]?.length
                      ? question.answer[language]
                      : "N/A"
                  }
                  className="mb-0"
                />
              )}
            </div>

            {/* <div className='col'>
          <div className='font-semibold'>Type:</div>
          {question.year === 1000
            ? 'Test series'
            : question.year === 1010
            ? 'QUIZ'
            : question.year === 1020
            ? 'DPP'
            : question.year === 1030
            ? 'Assignment'
            : question.year === 1040
            ? 'Work Book'
            : question.year === 1050
            ? 'Other'
            : question.year}
        </div> */}

            <div className="col">
              <div className="font-semibold">Marks: </div>+
              {question.markingRule?.positive}/-
              {question.markingRule?.negative}
            </div>

            <div className="col">
              <div className="font-semibold">Set:</div>
              {question?.set ? question.set : "N/A"}
            </div>

            <div className="col">
              <div className="font-semibold"> Mode:</div>
              <div style={{ textTransform: "uppercase" }}>{question?.mode}</div>
            </div>

            <div className="col">
              <div className="font-semibold"> Available in Languages:</div>

              <div className="flex justify-center gap-x-1 mt-1">
                {Object.keys(question.text).map((lang) =>
                  question.text[lang].length ? (
                    <div className="capitalize bg-gray-400 text-white px-2 py-1 rounded-sm text-xs">
                      {getLanguageName(lang)}
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-col justify-center">
          <Button
            color="success"
            className="text-sm mb-3"
            onClick={() =>
              push(`/view/question/${question._id}`, {
                questionId: question._id,
              })
            }
          >
            View
          </Button>

          <Button
            color="warning"
            style={{ background: "yellow" }}
            className="text-sm mb-3"
            onClick={() => push("/edit/question", { question })}
          >
            Edit
          </Button>

          <Button
            color="primary"
            style={{
              background: question.review ? "gray" : "green",
            }}
            className="text-sm mb-3"
            onClick={() => toggleReview(question)}
          >
            {question.review ? "Mark Unreviewed" : "Mark Review"}
          </Button>

          {role === Admin.MASTER && (
            <Button
              color="primary"
              className="text-sm mb-3"
              onClick={() => togglePublish(question)}
            >
              {question.publish ? "Unpublish" : "Publish"}
            </Button>
          )}

          {(role === "exam" || role === "master" || role === "department") && (
            <Button
              color="danger"
              style={{ background: "red" }}
              className="text-sm"
              onClick={() => deleteQuestion(question)}
            >
              Delete
            </Button>
          )}
        </div>
      </div>

      <hr />

      <div>
        <div className="font-semibold">Solution:</div>

        <div className="d-flex gap-3">
          <div className="w-50">
            <p className="text-break" style={{ whiteSpace: "pre-line" }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: question.solution.text[language],
                }}
              />
            </p>
          </div>

          <div className="border-end"></div>

          <div className="w-50">
            <Markup latex={question.solution.text[language]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionBox;
