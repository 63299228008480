import { useHistory } from "react-router-dom";
import { Button } from "../../components/buttons";
import { Markup } from "../../components/displays";

const ReportBox = ({ report, toggleResolved }) => {
  const { push } = useHistory();

  return (
    <div className="border mb-3 rounded p-3">
      <div className="d-flex gap-4">
        <div className="col">
          {report.comment && <div className="mb-3">{report.comment}</div>}

          <div className="row">
            {report.reference && (
              <div className="col">
                <div className="font-semibold">Reference:</div>
                <div className="capitalize">{report.reference}</div>
              </div>
            )}

            {report.type && (
              <div className="col">
                <div className="font-semibold">Type:</div>
                <div className="capitalize">{report.type}</div>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex flex-col justify-center">
          <Button
            color="primary"
            style={{
              background: report.resolved ? "gray" : "green",
              width: "136px",
            }}
            className="text-sm mb-3"
            onClick={() => toggleResolved(report)}
          >
            {report.resolved ? "Mark Unresolved" : "Mark Resolved"}
          </Button>
        </div>
      </div>

      <hr />

      <div className="d-flex gap-4">
        <div className="col">
          <div className="font-semibold">Question:</div>

          <Markup latex={report.questionDetails.text["en"]} />
        </div>

        <div className="d-flex flex-col justify-center">
          <Button
            color="success"
            className="text-sm mb-3"
            style={{ width: "136px" }}
            onClick={() =>
              push(`/view/question/${report.questionDetails._id}`, {
                questionId: report.questionDetails._id,
              })
            }
          >
            View
          </Button>

          <Button
            color="warning"
            style={{ background: "yellow", width: "136px" }}
            className="text-sm"
            onClick={() =>
              push("/edit/question", { question: report.questionDetails })
            }
          >
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportBox;
