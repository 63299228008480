import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, Submit } from "../../components/buttons";
import CommonInput from "../../components/CommonInput";
import { Input, Select, Time } from "../../components/inputs";
import { Section } from "../../components/Section";
import { AWS_DIRECTORIES, LANGUAGES } from "../../constants/Common";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";

import {
  authUser,
  selectedDepartmentObject,
  selectedLanguage,
} from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { Type } from "../../utilities/constants";
import { checkError } from "../../utilities/error";
import { showToast } from "../../utilities/toast";

const EditPreviousYear = () => {
  const { department, id } = useParams();
  const { goBack } = useHistory();

  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const [year, setYear] = useState();
  const [name, setName] = useState();
  const [time, setTime] = useState(0);
  const [marks, setMarks] = useState(0);
  const [publish, setPublish] = useState();
  const [description, setDescription] = useState();
  const [instructions, setInstructions] = useState();
  const [choice, setChoice] = useState("");
  const [toRenderSection, setToRenderSection] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");

  const user = useRecoilValue(authUser);
  const selectedDepart = useRecoilValue(selectedDepartmentObject);
  const [language, setSelectedLanguage] = useRecoilState(selectedLanguage);

  const { getSubjectByDepartment, getChaptersBySubject, subjects, chapters } =
    useData();

  const getSubjects = async () => {
    await getSubjectByDepartment(department);
  };

  const getChapters = async () => {
    await getChaptersBySubject(selectedSubject);
  };

  useEffect(() => {
    getSubjects();
    getChapters();
  }, [department, selectedSubject]);

  useEffect(() => {
    setTo(Number(selectedDepart.yearRange.to));
    setFrom(Number(selectedDepart.yearRange.from));
  }, [selectedDepart]);

  useEffect(() => {
    const getData = async () => {
      const res = await instance.get(
        endpoints.getPreviousYearPaper + `?id=${id}`
      );
      setPrevious(res.data);
    };

    getData();
  }, [id]);

  function setPrevious(value) {
    value.sections.forEach((sec, i) => (sec.sectionId = "section" + i));
    setChoice(value.selectSection);
    setTime(value.time);
    setSelectedChapter(value.chapter);
    setSelectedSubject(value.subject);

    setName(value.name);
    setInstructions(value.instructions);
    setDescription(value.description);

    setMarks(value.marks);
    setToRenderSection(value.sections);
    setPublish(value.publish);

    setYear(value.year);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    let questionIds = [];
    let returnFunc;
    const sectionsClone = JSON.parse(JSON.stringify(toRenderSection));

    sectionsClone.forEach((section) => {
      returnFunc = checkError(section);
      section.questions.forEach((question) => {
        questionIds.push(question._id);
      });
      section.questions = questionIds;
      questionIds = [];
    });

    if (returnFunc) return;

    const data = {
      name,
      updatedBy: user.userId,
      time,
      marks,
      subject: selectedSubject,
      chapter: selectedChapter,
      selectSection: choice,
      instructions,
      description,
      year,
      sections: sectionsClone,
      _id: id,
    };

    try {
      await instance.put(endpoints.updatePreviousPaper, data);
      showToast({
        type: "success",
        message: "Successfully update the previous paper",
      });
      goBack();
    } catch (err) {
      showToast({
        type: "error",
        message: err?.response?.message,
      });
    }
  };

  const handleSection = () => {
    let index = toRenderSection.length;
    let flag = true;
    while (flag === true) {
      let temp = false;
      // eslint-disable-next-line no-loop-func
      toRenderSection.forEach((sec) => {
        if (sec.sectionId === `section${index}`) temp = true;
      });
      if (temp) {
        flag = true;
        index++;
      } else flag = false;
    }
    const toAdd = {
      name: "",
      questions: [],
      sectionId: "section" + index,
      tackle: 0,
      compulsory: null,
    };
    setToRenderSection([...toRenderSection, toAdd]);
  };

  const deleteSection = (selected) =>
    setToRenderSection((prev) =>
      prev.filter((sec) => sec.sectionId !== selected.sectionId)
    );

  const onLanguageChange = (e) => {
    setSelectedLanguage(e);

    setName((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });

    setInstructions((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });

    setDescription((prev) => {
      if (!prev[e.value]) return { ...prev, [e.value]: "" };
      else return prev;
    });
  };

  const allQuestions = useMemo(() => {
    const data = [];
    toRenderSection.forEach((section) =>
      section.questions.forEach((ques) => data.push(ques))
    );
    return data;
  }, [toRenderSection]);

  return (
    <>
      <div className="container mb-5">
        <div className=" flex-column justify-content-center">
          <div className="flex justify-center items-center border p-3">
            <h2 className="space-heading">{name?.[language.value]}</h2>
          </div>

          <form className="horizontal p-2 mt-3" onSubmit={onSubmit}>
            <div className="flex flex-wrap justify-normal mb-5 gap-x-10">
              <Input
                className="!w-56"
                id="name"
                label="Name"
                value={name?.[language?.value]}
                onChange={(e) =>
                  setName((prev) => ({ ...prev, [language.value]: e }))
                }
              />

              <Input value={marks} label="Total marks" onChange={setMarks} />

              <Input
                name="choice"
                label="Choice"
                type="number"
                value={choice}
                onChange={(e) => {
                  if (e > -1) {
                    setChoice(e);
                  }
                }}
              />
              <Time value={time} label="Time" onChange={setTime} />

              <Select onChange={setYear} value={year} label="Year">
                {Array.from(
                  { length: to - from + 1 },
                  (_, index) => from + index
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>

              <Select
                onChange={setSelectedSubject}
                value={selectedSubject}
                label="Subject"
              >
                {subjects?.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Select>

              <Select
                onChange={(e) => setSelectedChapter(e)}
                value={selectedChapter}
                label="Chapters"
              >
                {chapters?.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </Select>

              <div className="mt-1">
                <h6 className="mb-1">Select Language</h6>
                <ReactSelect
                  onChange={onLanguageChange}
                  options={selectedDepart?.languages?.map((lang) => {
                    const label = Object.keys(LANGUAGES).find(
                      (key) => LANGUAGES[key] === lang
                    );
                    return {
                      value: lang,
                      label: label.replace(/^./, label[0].toUpperCase()),
                    };
                  })}
                  value={language}
                  placeholder="Select Language"
                  className="w-52"
                />
              </div>
            </div>
            <div className="mb-5 mx-4">
              <CommonInput
                language={language.value}
                directory={AWS_DIRECTORIES.PREVIOUS_YEAR_PAPER}
                label="General Instructions"
                value={instructions?.[language.value]}
                onChange={setInstructions}
              />
            </div>

            <div className="mb-3 mx-4">
              <CommonInput
                language={language.value}
                directory={AWS_DIRECTORIES.PREVIOUS_YEAR_PAPER}
                label="Description"
                value={description?.[language.value]}
                onChange={setDescription}
              />
            </div>

            {toRenderSection?.map((section, index) => {
              return (
                <Section
                  allQuestions={allQuestions}
                  language={language}
                  type={Type.PREVIOUSYEARS}
                  key={index + section.sectionId}
                  index={index}
                  section={section}
                  setToRenderSection={setToRenderSection}
                  deleteSection={deleteSection}
                />
              );
            })}

            <div className="d-flex justify-content-between mt-3 mb-3">
              <Button width="25" onClick={handleSection}>
                Add Section
              </Button>

              {user?.role !== "master" && publish ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#ff000066",
                    padding: "6px 10px",
                    borderRadius: "5px",
                    cursor: "not-allowed",
                  }}
                >
                  Published Previous Year cannot be edited
                </div>
              ) : null}

              {user.role !== "master" && !publish && (
                <Submit
                  width="25"
                  disabled={
                    time === 0 ||
                    !name[language.value] ||
                    !marks ||
                    !toRenderSection?.length ||
                    !instructions[language.value]
                  }
                />
              )}

              {user?.role === "master" && (
                <Submit
                  width="25"
                  disabled={
                    time === 0 ||
                    !name[language.value] ||
                    !marks ||
                    !toRenderSection?.length ||
                    !instructions[language.value]
                  }
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditPreviousYear;
