import { useEffect, useState } from "react";
import { ActiveButton, EditButton } from "../../components/buttons";
import useData from "../../hooks/useData";
import { BASE, useFetch } from "../../utilities/apis";

const ViewChapters = () => {
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedChapter, setEditedChapter] = useState({});
  const { update } = useFetch();
  const {
    getExams,
    getDepartmentByExam,
    getSubjectByDepartment,
    getChaptersBySubject,
  } = useData();

  useEffect(() => {
    const fetchExams = async () => {
      const exams = await getExams();
      if (exams) setExams(exams);
    };

    fetchExams();
  }, []);

  useEffect(() => {
    if (selectedExam) {
      const fetchDepartments = async () => {
        const departments = await getDepartmentByExam(selectedExam);
        if (departments) setDepartments(departments);
      };

      fetchDepartments();
    }
  }, [selectedExam]);

  useEffect(() => {
    if (selectedExam && selectedDepartment) {
      const fetchSubjects = async () => {
        const subjects = await getSubjectByDepartment(selectedDepartment);
        if (subjects) setSubjects(subjects);
      };

      fetchSubjects();
    }
  }, [selectedExam, selectedDepartment]);

  useEffect(() => {
    if (selectedExam && selectedDepartment && selectedSubject) {
      const fetchChapters = async () => {
        const chapters = await getChaptersBySubject(selectedSubject);
        if (chapters) setChapters(chapters);
      };

      fetchChapters();
    }
  }, [selectedExam, selectedDepartment, selectedSubject]);

  const handleEditClick = (index, chapter) => {
    if (editingIndex === null) {
      setEditingIndex(index);
      setEditedChapter({ code: chapter.code, title: chapter.title });
    } else if (editingIndex === index) {
      handleUpdate(chapter, editedChapter, true);
    } else {
      setEditingIndex(index);
      setEditedChapter({ code: chapter.code, title: chapter.title });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedChapter({ ...editedChapter, [name]: value });
  };

  const handleUpdate = (chapter, data, edit = false) => {
    update(`${BASE}update/chapter/?id=${chapter._id}`, data, (error, res) => {
      if (error) return console.log(error);
      const clone = [...chapters];
      const index = clone.findIndex((e) => e._id === chapter._id);
      clone[index] = res;
      setChapters(clone);

      if (edit) {
        setEditingIndex(null);
        setEditedChapter({});
      }
    });
  };

  return (
    <div>
      <form>
        <div className="mb-3">
          <label htmlFor="exam" className="form-label">
            Select exam
          </label>
          <select
            className="form-select"
            id="exam"
            value={selectedExam}
            onChange={(e) => setSelectedExam(e.target.value)}
          >
            <option value="">Select exam</option>
            {exams.map((exam, index) => {
              return (
                <option key={index} value={exam._id}>
                  {exam.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="department" className="form-label">
            Select department
          </label>
          <select
            className="form-select"
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Select department</option>
            {departments.map((department, index) => {
              return (
                <option key={index} value={department._id}>
                  {department.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            Select subject
          </label>
          <select
            className="form-select"
            id="subject"
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            <option value="">Select subject</option>
            {subjects.map((subject, index) => {
              return (
                <option key={index} value={subject._id}>
                  {subject.title}
                </option>
              );
            })}
          </select>
        </div>
      </form>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Chapter code</th>
            <th scope="col">Chapter title</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {chapters.map((chapter, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="code"
                      className="table-control"
                      value={editedChapter.code}
                      onChange={handleInputChange}
                    />
                  ) : (
                    chapter.code
                  )}
                </td>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="title"
                      className="table-control"
                      value={editedChapter.title}
                      onChange={handleInputChange}
                    />
                  ) : (
                    chapter.title
                  )}
                </td>

                <td>
                  <EditButton
                    className="me-3"
                    editing={editingIndex === index}
                    onClick={() => handleEditClick(index, chapter)}
                  />

                  <ActiveButton
                    active={chapter.display}
                    onClick={() =>
                      handleUpdate(chapter, { display: !chapter.display })
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewChapters;
