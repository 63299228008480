import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../../components/buttons";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const ViewForum = () => {
  const { state } = useLocation();

  const [forum, setForum] = useState(state.forum);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const getAnswers = async () => {
      try {
        const { data } = await instance.get(
          `${endpoints.getForumAnswers}/${forum._id}`
        );

        setAnswers(data);
      } catch (err) {
        showToast("error", err.response.data.message);
      }
    };

    if (forum._id) getAnswers();
  }, [forum._id]);

  const onToggleRecommend = async () => {
    try {
      const res = await instance.put(`${endpoints.updateForum}/${forum._id}`, {
        recommended: !forum.recommended,
      });

      if (res.data) {
        setForum((prev) => ({ ...prev, recommended: res.data.recommended }));
      }
    } catch (err) {
      showToast("error", err.response.data.message);
    }
  };

  const onDelete = () => {};

  const onDeleteAnswer = async (answer) => {
    try {
      await instance.delete(`${endpoints.deleteForumAnswer}/${answer._id}`);
      const clone = answers.filter((a) => a._id !== answer._id);
      setAnswers(clone);
    } catch (err) {
      showToast("error", err.response.data.message);
    }
  };

  return (
    <div className="container">
      <div className="mb-4">
        <p>{forum.text}</p>

        <p className="text-sm text-gray-500">
          Posted on: {new Date(forum.createdAt).toLocaleString()}
        </p>
      </div>

      <div className="flex justify-between items-center">
        <div>
          {forum.postedBy.name && (
            <div className="text-sm">
              <strong>Posted by:</strong> {forum.postedBy.name}
            </div>
          )}
        </div>

        <div className="flex gap-3 items-center">
          <Button
            color="warning"
            className="text-sm"
            style={{ background: "yellow" }}
            onClick={onToggleRecommend}
          >
            {forum.recommended ? "Unrecommend" : "Recommend"}
          </Button>

          <Button
            color="danger"
            className="text-sm"
            style={{ background: "red" }}
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      </div>

      <hr className="my-5" />

      {answers.map((answer) => (
        <div key={answer._id} className="border rounded p-3 mb-3">
          <div className="mb-4">
            <p>{answer.text}</p>

            <p className="text-sm text-gray-500">
              Posted on: {new Date(answer.createdAt).toLocaleString()}
            </p>
          </div>

          <div className="flex justify-between items-center">
            <div>
              {answer.postedBy && (
                <div className="text-sm">
                  <strong>Posted by:</strong> {answer.postedBy.name}
                </div>
              )}
            </div>

            <div>
              <Button
                color="danger"
                className="text-sm"
                style={{ background: "red" }}
                onClick={() => onDeleteAnswer(answer)}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewForum;
