import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { Button } from "../../components/buttons";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { instance } from "../../services/https/inceptors";
import { STUDENTS } from "../../utilities/apis";
import { showToast } from "../../utilities/toast";

const MakePayment = () => {
  const [exams, setExams] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedExpiry, setSelectedExpiry] = useState("");

  const { getExams, getDepartmentByExam } = useData();

  useEffect(() => {
    const fetchExams = async () => {
      const exams = await getExams();
      if (exams) setExams(exams);
    };

    fetchExams();
  }, []);

  useEffect(() => {
    if (selectedExam) {
      const fetchDepartments = async () => {
        const departments = await getDepartmentByExam(selectedExam);
        if (departments) setDepartments(departments);
      };

      fetchDepartments();
    }
  }, [selectedExam]);

  useEffect(() => {
    if (selectedExam && selectedDepartment) {
      const fetchPayments = async () => {
        const res = await instance.get(
          `${endpoints.getAllPayments}?exam=${selectedExam}&department=${selectedDepartment}`
        );
        if (res) setPayments(res.data);
      };

      fetchPayments();
    }
  }, [selectedExam, selectedDepartment]);

  const loadStudents = (username, callback) => {
    instance.get(`${STUDENTS}search/?username=${username}`).then((res) => {
      if (!res || !res.data || res.error) {
        return callback([]);
      }

      callback(
        res.data.map((student) => ({
          value: student._id,
          label: `${student.username} - ${student.name}`,
        }))
      );
    });
  };

  const makePayment = async () => {
    const payment = payments.find((payment) => payment._id === selectedPayment);
    const subscription = payment.subscriptions.find(
      (subscription) => subscription._id === selectedSubscription
    );

    const description = payment.description.split(", ");

    const data = {
      user: selectedStudent,
      exam: selectedExam,
      department: selectedDepartment,
      title: payment.title,
      mode: payment.mode,
      discount: payment.discount,
      actualPrice: subscription.actualPrice,
      expiryDate: selectedExpiry,
      description,
    };

    try {
      await instance.post(endpoints.makePayment, data);
      showToast({ type: "success", message: "Payment made successfully" });

      setSelectedStudent("");
      setSelectedExam("");
      setSelectedDepartment("");
      setSelectedPayment("");
      setSelectedSubscription("");
      setSelectedExpiry("");
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="flex justify-center">
      <div style={{ width: "450px" }}>
        <h1 className="text-center mb-5">Make Payment</h1>

        <div className="mb-3">
          <label htmlFor="exam" className="form-label">
            Select student
          </label>
          <AsyncSelect
            loadOptions={loadStudents}
            placeholder="Select Student"
            onChange={(e) => setSelectedStudent(e?.value)}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="exam" className="form-label">
            Select exam
          </label>
          <select
            className="form-select"
            id="exam"
            value={selectedExam}
            onChange={(e) => setSelectedExam(e.target.value)}
          >
            <option value="">Select exam</option>
            {exams.map((exam, index) => {
              return (
                <option key={index} value={exam._id}>
                  {exam.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="department" className="form-label">
            Select department
          </label>
          <select
            className="form-select"
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Select department</option>
            {departments.map((department, index) => {
              return (
                <option key={index} value={department._id}>
                  {department.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="department" className="form-label">
            Select payment
          </label>
          <select
            className="form-select"
            id="payment"
            value={selectedPayment}
            onChange={(e) => setSelectedPayment(e.target.value)}
          >
            <option value="">Select payment</option>
            {payments.map((payment, index) => {
              return (
                <option key={index} value={payment._id}>
                  {payment.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="subscription" className="form-label">
            Select subscription
          </label>
          <select
            className="form-select"
            id="subscription"
            value={selectedSubscription}
            onChange={(e) => setSelectedSubscription(e.target.value)}
          >
            <option value="">Select subscription</option>
            {(
              payments.find((payment) => payment._id === selectedPayment) || {
                subscriptions: [],
              }
            ).subscriptions.map((subscription, index) => {
              return (
                <option key={index} value={subscription._id}>
                  {subscription.duration}{" "}
                  {subscription.durationType.toLowerCase()}(s) -{" "}
                  {subscription.actualPrice}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="expiry" className="form-label">
            Expiry date
          </label>
          <input
            type="date"
            className="form-control"
            id="expiry"
            value={selectedExpiry}
            onChange={(e) => setSelectedExpiry(e.target.value)}
          />
        </div>

        <div>
          <Button
            onClick={makePayment}
            className="w-100"
            disabled={
              !selectedStudent ||
              !selectedExam ||
              !selectedDepartment ||
              !selectedPayment ||
              !selectedSubscription ||
              !selectedExpiry
            }
          >
            Make Payment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MakePayment;
