import { useState } from "react";
import { useParams } from "react-router-dom";
import { Submit } from "../../components/buttons";
import { Date, Input, Textarea } from "../../components/inputs";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const AddJob = () => {
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");
  const { department, exam } = useParams();

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        role,
        description,
        deadline,
        exam,
        department,
      };

      await instance.post(endpoints.addJob, data);

      showToast({
        type: "success",
        message: "Job Added Successfully",
      });

      setRole("");
      setDescription("");
      setDeadline("");
    } catch (err) {
      showToast({
        type: "error",
        message: "Assignment Cannot be added",
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center ">
        <h4 className="space-heading mb-5">Create Job</h4>
      </div>

      <div className="flex justify-center">
        <form className="w-25" onSubmit={onSubmit}>
          <Input id="role" label="Role" onChange={setRole} />

          <Date id="deadline" label="Deadline" onChange={setDeadline} />

          <Textarea
            id="description"
            label="Description"
            className="mt-3"
            rows={10}
            onChange={setDescription}
          />

          <div className="d-flex justify-content-center mt-4">
            <Submit disabled={!role || !description || !deadline} />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddJob;
