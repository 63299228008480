import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button } from "../../components/buttons";
import { Select } from "../../components/inputs";
import { Loader } from "../../components/Loaders";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import {
  authUser,
  chapter,
  filteredReports,
  subject,
  topic,
  year,
} from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { Type } from "../../utilities/constants";
import { years } from "../../utilities/helpers";
import { showToast } from "../../utilities/toast";
import ReportBox from "./ReportBox";

const Reports = () => {
  const { department, exam } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userId } = useRecoilValue(authUser);

  const [selectedSubject, setSelectedSubject] = useRecoilState(subject);
  const [selectedChapter, setSelectedChapter] = useRecoilState(chapter);
  const [selectedTopic, setSelectedTopic] = useRecoilState(topic);
  const [selectedYear, setSelectedYear] = useRecoilState(year);
  const [selectedResolved, setSelectedResolved] = useState(false);
  const [reports, setReports] = useRecoilState(filteredReports);

  const { getSubjectByDepartment, getChaptersBySubject, getTopicsByChapter } =
    useData();

  const getSubject = async () => {
    const subjects = await getSubjectByDepartment(department);
    setSubjects(subjects);
  };

  const getChapters = async () => {
    const chapter = await getChaptersBySubject(selectedSubject);
    setChapters(chapter);
  };

  const getTopics = async () => {
    const topic = await getTopicsByChapter(selectedChapter);
    setTopics(topic);
  };

  useEffect(() => {
    if (selectedTopic) getTopics();
    if (selectedSubject) getChapters();
    if (department) getSubject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  const selectSubject = async (subject) => {
    setSelectedTopic("");
    setSelectedChapter("");
    setSelectedSubject(subject);
    const chapter = await getChaptersBySubject(subject);
    setChapters(chapter);
  };

  const selectChapter = async (chapter) => {
    setSelectedTopic("");
    setSelectedChapter(chapter);
    const topic = await getTopicsByChapter(chapter);
    setTopics(topic);
  };

  function selectTopic(e) {
    setSelectedTopic(e);
  }

  const fetchReports = async () => {
    setLoading(true);

    try {
      const res = await instance.get(
        `${endpoints.getReports}?department=${department}&exam=${exam}&subject=${selectedSubject}&chapter=${selectedChapter}&topic=${selectedTopic}&year=${selectedYear}&resolved=${selectedResolved}`
      );
      setReports(res.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Failed to fetch reports",
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleResolved = async (report) => {
    try {
      const res = await instance.put(
        `${endpoints.toggleReportResolved}?_id=${report._id}&resolvedBy=${userId}`
      );

      const clone = [...reports];
      const index = clone.findIndex((q) => q._id === report._id);
      const newReport = { ...clone[index] };
      newReport.resolved = res.data.resolved;
      clone[index] = newReport;
      setReports(clone);

      showToast({
        type: "success",
        message: res.data.resolved
          ? "Report marked resolved"
          : "Report marked unresolved",
      });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const deleteReport = async (report) => {
    try {
      const confirmModal = window.confirm(
        "Are you sure want to delete the report"
      );

      if (confirmModal) {
        await instance.delete(`${endpoints.deleteReport}?_id=${report._id}`);

        const clone = [...reports];
        const index = clone.findIndex((q) => q._id === report._id);
        clone.splice(index, 1);
        setReports(clone);

        showToast({
          type: "success",
          message: "Report deleted successfully",
        });
      }
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="container">
      <div className="row row-cols-3 mt-5">
        <div className="col">
          <Select
            label="Subject"
            value={selectedSubject}
            onChange={selectSubject}
            vertical
          >
            {subjects?.map((sub) => (
              <option key={sub?.code} value={sub?._id}>
                {sub.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Chapter"
            value={selectedChapter}
            onChange={selectChapter}
            vertical
          >
            {chapters?.map((chapter) => (
              <option key={chapter.code} value={chapter._id}>
                {chapter.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Topic"
            value={selectedTopic}
            onChange={selectTopic}
            vertical
          >
            {topics?.map((topic) => (
              <option key={topic.code} value={topic._id}>
                {topic.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e)}
            vertical
          >
            <option value={Type.SERIES}>Test Series</option>
            <option value={Type.QUIZ}>Quiz</option>
            <option value={Type.Assignment}>Assignment</option>
            <option value={Type.DPP}>DPP</option>
            <option value={Type.Workbook}>Workbook</option>
            <option value={Type.Other}>Other</option>

            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Resolved"
            value={selectedResolved}
            onChange={(e) => setSelectedResolved(e)}
            vertical
          >
            <option value={true}>Resolved</option>
            <option value={false}>Unresolved</option>
          </Select>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <div></div>

        <Button color="primary" width="10">
          Total: {reports.length}
        </Button>

        <Button
          disabled={loading}
          color="success"
          width="25"
          onClick={fetchReports}
        >
          Go
        </Button>
      </div>

      <hr className="m-4" />

      {loading ? (
        <Loader />
      ) : (
        <div className="mb-5">
          {reports.map((report) => (
            <ReportBox
              key={report._id}
              report={report}
              toggleResolved={toggleResolved}
              deleteReview={deleteReport}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Reports;
