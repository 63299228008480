import { useCallback, useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../components/buttons";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import { MdEdit } from "react-icons/md";
import dayjs from "dayjs";
import { getBooleanIcon } from "../../utilities/helpers";

const Payment = () => {
  const { push } = useHistory();
  const { exam, department } = useParams();
  const [payments, setPayments] = useState([]);

  const getAllPayment = useCallback(async () => {
    const res = await instance.get(
      `${endpoints.PAYMENT}/get-all?exam=${exam}&department=${department}`
    );

    setPayments(res.data);
  }, [department, exam]);

  const deletePayment = async (id) => {
    try {
      const message = "Are you sure you want to delete this payment?";
      const yes = window.confirm(message);

      if (yes) {
        await instance.delete(`${endpoints.PAYMENT}/delete?_id=${id}`);
        setPayments((prev) => prev.filter((filt) => filt._id !== id));
        showToast({
          type: "success",
          message: "Successfully Deleted the payment",
        });
      }
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  useEffect(() => {
    getAllPayment();
  }, [getAllPayment]);

  return (
    <div className="text-center container ">
      <h2 className="space-heading text-center mb-5">Payments</h2>

      <div className="flex justify-end mb-5">
        <Button onClick={() => push("/create-payment")} className="w-25">
          Add New
        </Button>
      </div>

      {payments && payments?.length ? (
        <table id="reviews">
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Mode</th>
            <th>Type</th>
            <th>Expiry Date</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Allow Referral</th>
            <th>Actions</th>
          </tr>

          {payments?.map((payment) => (
            <tr>
              <td>{payment.title}</td>

              <td>{payment.description}</td>

              <td>
                {payment.mode?.split("_").join(" ").split("+").join(" + ")}{" "}
              </td>
              <td>{payment.type} </td>
              <td>
                {payment?.expiryDate
                  ? dayjs(payment.expiryDate).format("DD/MM/YYYY")
                  : "N/A"}
              </td>
              <td>{payment.actualPrice ?? "N/A"}</td>
              <td>{payment.discount ?? 0}</td>
              <td className="text-center">
                {getBooleanIcon(payment?.allowReferral)}
              </td>

              <td className="text-center">
                <AiFillDelete
                  onClick={() => deletePayment(payment._id)}
                  className="c-pointer"
                  size={20}
                />
                <MdEdit
                  onClick={() => push(`/edit-payment/${payment._id}`)}
                  className="c-pointer"
                  size={20}
                />
              </td>
            </tr>
          ))}
        </table>
      ) : null}
    </div>
  );
};

export default Payment;
