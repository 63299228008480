import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/buttons";
import { Markup } from "../../components/displays";
import { Input } from "../../components/inputs";
import { endpoints } from "../../constants/Endpoints";
import { department, selectedLanguage } from "../../providers";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import AddContentModal from "./Modals/AddContentModal";
import AddDppModal from "./Modals/AddDppModal";

const CourseTopic = () => {
  const { topicId, topicName } = useParams();
  const [topicData, setTopicData] = useState();
  const [showContentModal, setShowContentModal] = useState(false);
  const [showDppModal, setShowDppModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [videoLink, setVideoLink] = useState({});
  const language = useRecoilValue(selectedLanguage);
  const departmentId = useRecoilValue(department).split("/")[1];

  const getTopicData = async () => {
    const res = await instance.get(
      `${endpoints.getCourseTopicData}?topicId=${topicId}`
    );
    setTopicData(res.data);
    setVideoLink(res.data.videoLink);
  };

  useEffect(() => {
    if (topicId) getTopicData();
  }, [topicId]);

  const deleteContent = async (contentId) => {
    try {
      await instance.delete(
        `${endpoints.deleteCourseContent}?contentId=${contentId}&topicId=${topicId}`
      );

      const topicDataClone = JSON.parse(JSON.stringify(topicData));
      topicDataClone.content = topicDataClone.content.filter(
        (content) => content._id !== contentId
      );

      setTopicData(topicDataClone);
      showToast({ type: "success", message: "Successfully deleted Content!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const deleteDpp = async (dppId) => {
    try {
      await instance.delete(
        `${endpoints.deleteCourseDPP}?dppId=${dppId}&topicId=${topicId}`
      );

      const topicDataClone = JSON.parse(JSON.stringify(topicData));
      topicDataClone.dpp = topicDataClone.dpp.filter(
        (dpp) => dpp._id !== dppId
      );

      setTopicData(topicDataClone);
      showToast({ type: "success", message: "Successfully deleted DPP!" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const saveVideoLink = async () => {
    setIsDisable(false);
    await instance.post(endpoints.addCourseVideoLink, {
      topicId,
      videoLink,
    });

    showToast({ type: "success", message: "Successfully added video link" });
  };

  return (
    <div className="mb-5">
      <h2
        style={{
          background: "gainsboro",
          width: "fit-content",
          padding: "10px 80px",
          margin: "auto",
          fontSize: 23,
        }}
        className="text-center text-uppercase"
      >
        {topicName}
      </h2>

      <div className="mt-5">
        <h5 className="text-center text-uppercase mt-5 section-heading">
          Video Link
        </h5>

        <div className="w-40 m-auto">
          <Input
            disabled={!isDisable}
            id="name"
            label="Video Link"
            value={videoLink?.[language?.value]}
            onChange={(e) =>
              setVideoLink((prev) => ({ ...prev, [language.value]: e }))
            }
          />

          <div className="d-flex justify-content-end">
            {!isDisable ? (
              <Button onClick={() => setIsDisable(true)}>Edit</Button>
            ) : (
              <Button onClick={saveVideoLink}>Save</Button>
            )}
          </div>
        </div>
      </div>

      <div className="mb-5">
        <h5 className="text-center text-uppercase mt-5 section-heading">
          Content
        </h5>
        <div className="d-flex mx-3 accent-body mt-4">
          <div className="all-chapters w-50">
            {topicData?.content?.length ? (
              <div className="mt-4">
                {topicData?.content?.map((content) => (
                  <div className="chapters-list">
                    <div className="chapter-title">
                      <Markup
                        latex={content.contents[0].title[language.value]}
                        className="mb-0"
                      />
                    </div>
                    <div className="actions">
                      <Link to={`/view-content/${content?._id}`}>
                        <BsFillEyeFill size={26} />
                      </Link>
                      <div>
                        <AiFillDelete
                          onClick={() => deleteContent(content?._id)}
                          size={26}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center mt-4"> No Content Added!</div>
            )}
          </div>

          <div className="w-50 d-flex flex-column align-items-center justify-content-center gap-4">
            <Button
              onClick={() => setShowContentModal(true)}
              color="success"
              width="30"
              className="search-assignment"
            >
              Add Content
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <h5 className="text-center text-uppercase mt-5 section-heading">
          Daily Practice Problems (DPP)
        </h5>
        <div className="d-flex mx-3 accent-body mt-4">
          <div className="all-chapters w-50">
            {topicData?.dpp?.length ? (
              <div className="mt-4">
                {topicData?.dpp?.map((dpp) => (
                  <div className="chapters-list">
                    <div className="chapter-title">
                      {dpp?.name?.[language.value]}
                    </div>
                    <div className="actions">
                      <Link to={`/edit-dpp/${dpp?._id}/${departmentId}`}>
                        <BsFillEyeFill size={26} />
                      </Link>
                      <div>
                        <AiFillDelete
                          onClick={() => deleteDpp(dpp?._id)}
                          size={26}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center mt-4"> No DPP Added!</div>
            )}
          </div>

          <div className="w-50 d-flex flex-column align-items-center justify-content-center gap-4">
            <Button
              onClick={() => setShowDppModal(true)}
              color="success"
              width="30"
              className="search-assignment"
            >
              Add DPP
            </Button>
          </div>
        </div>
      </div>

      {showContentModal && (
        <AddContentModal
          closeModal={() => setShowContentModal(false)}
          data={topicData}
          id={topicId}
          setData={setTopicData}
        />
      )}

      {showDppModal && (
        <AddDppModal
          closeModal={() => setShowDppModal(false)}
          data={topicData}
          id={topicId}
          setData={setTopicData}
        />
      )}
    </div>
  );
};

export default CourseTopic;
