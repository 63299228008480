import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/buttons";
import { Select } from "../../components/inputs";
import { endpoints } from "../../constants/Endpoints";
import { authUser } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { convertEnumToString } from "../../utilities/helpers";
import { showToast } from "../../utilities/toast";

const Referrals = () => {
  const { push } = useHistory();

  const { examPermissions, role: userRole } = useRecoilValue(authUser);

  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState();
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [referralCodeData, setReferralCodeData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getExams = async () => {
      const query = `?examPermissions=${examPermissions.toString()}`;
      const res = await instance.get(`${endpoints.getAllowedExams}${query}`);
      setExams(res.data);
    };

    getExams();
  }, [examPermissions]);

  const onExamChange = async (examId) => {
    setSelectedExam(examId);
    setSelectedDepartment("");

    const res = await instance.get(
      `${endpoints.getDepartmentsByExam}?exam=${examId}`
    );

    setDepartments(res.data);
  };

  const getReferralCodes = async () => {
    let url = `${endpoints.referralCode}`;

    let urlParams = {};

    if (selectedExam) urlParams.exam = selectedExam;
    if (selectedDepartment) urlParams.department = selectedDepartment;

    if (Object.keys(urlParams).length) {
      const params = new URLSearchParams(urlParams);
      url += `?${params.toString()}`;
    }

    setLoading(true);

    try {
      const { data } = await instance.get(url);
      setReferralCodeData(data);
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  const deleteReferralCode = async (id) => {
    try {
      await instance.delete(`${endpoints.referralCode}/${id}`).then(() => {
        const clone = [...referralCodeData];
        const index = clone.findIndex((item) => item._id === id);
        clone.splice(index, 1);

        setReferralCodeData(clone);

        showToast({
          type: "success",
          message: "Referral code deleted successfully",
        });
      });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="container ">
      <div className="row row-cols-3 mt-5 align-items-center">
        <div className="col">
          <Select
            label="Select Exam"
            value={selectedExam}
            onChange={onExamChange}
          >
            {exams.map((exam) => (
              <option key={exam._id} value={exam._id}>
                {exam.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Select Department"
            value={selectedDepartment}
            onChange={setSelectedDepartment}
          >
            {departments.map((department) => (
              <option key={department._id} value={department._id}>
                {department.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Button onClick={() => getReferralCodes()} className="w-100 mt-2">
            Search
          </Button>
        </div>
      </div>

      <div className="my-4 d-flex justify-end">
        <Button onClick={() => push("/create-referral-code")} className="w-25">
          Add New
        </Button>
      </div>

      {referralCodeData?.length ? (
        <table id="reviews">
          <tr>
            <th>Title</th>
            <th>Product</th>
            <th>Expiry Date</th>
            <th>Discount</th>
            <th>Enabled</th>
            <th>Actions</th>
          </tr>

          {referralCodeData?.map((item) => (
            <tr>
              <td>{item?.title}</td>
              <td>{convertEnumToString(item?.product)}</td>
              <td>{dayjs(item.expiryDate).format("DD/MM/YYYY") ?? "N/A"}</td>
              <td>{item.discount ?? 0}%</td>
              <td>{item.status ? "Yes" : "No"}</td>

              <td className="text-center">
                <AiFillDelete
                  onClick={() => deleteReferralCode(item._id)}
                  className="c-pointer"
                  size={20}
                />
                <MdEdit
                  onClick={() => push(`/update-referral-code/${item._id}`)}
                  className="c-pointer"
                  size={20}
                />
              </td>
            </tr>
          ))}
        </table>
      ) : null}
    </div>
  );
};

export default Referrals;
