import { useEffect, useState } from "react";
import Modal from "react-modal";
import { ActiveButton, EditButton } from "../../components/buttons";
import { Image } from "../../components/inputs";
import { AWS_DIRECTORIES } from "../../constants/Common";
import useData from "../../hooks/useData";
import { BASE, useFetch } from "../../utilities/apis";

const ViewExams = () => {
  const [exams, setExams] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedExam, setEditedExam] = useState({});
  const [imageExam, setImageExam] = useState({});
  const [open, setOpen] = useState(false);
  const { getExams } = useData();
  const { update } = useFetch();

  const modalStyles = {
    content: {
      width: "40%",
      top: "53%",
      zIndex: 1,
      maxHeight: "calc(100vh - 7rem)",
      position: "fixed",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };

  useEffect(() => {
    const fetchExams = async () => {
      const exams = await getExams();
      if (exams) setExams(exams);
    };

    fetchExams();
  }, []);

  const handleEditClick = (index, exam) => {
    if (editingIndex === null) {
      setEditingIndex(index);
      setEditedExam({ code: exam.code, title: exam.title });
    } else if (editingIndex === index) {
      handleUpdate(exam, editedExam, true);
    } else {
      setEditingIndex(index);
      setEditedExam({ code: exam.code, title: exam.title });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedExam({ ...editedExam, [name]: value });
  };

  const handleUpdate = (exam, data, edit = false) => {
    update(`${BASE}update/exam/?id=${exam._id}`, data, (error, res) => {
      if (error) return console.log(error);
      const clone = [...exams];
      const index = clone.findIndex((e) => e._id === exam._id);
      clone[index] = res;
      setExams(clone);

      if (edit) {
        setEditingIndex(null);
        setEditedExam({});
      }
    });
  };

  const handleImageModal = (exam) => {
    setImageExam(exam);
    setOpen(true);
  };

  const closeImageModal = () => {
    setImageExam({});
    setOpen(false);
  };

  const handleImageChange = (link) => {
    const data = { image: link };
    handleUpdate(imageExam, data);
    setImageExam({ ...imageExam, image: link });
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Exam code</th>
            <th scope="col">Exam title</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {exams.map((exam, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="code"
                      className="table-control"
                      value={editedExam.code}
                      onChange={handleInputChange}
                    />
                  ) : (
                    exam.code
                  )}
                </td>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="title"
                      className="table-control"
                      value={editedExam.title}
                      onChange={handleInputChange}
                    />
                  ) : (
                    exam.title
                  )}
                </td>

                <td>
                  <EditButton
                    className="me-3"
                    editing={editingIndex === index}
                    onClick={() => handleEditClick(index, exam)}
                  />

                  <ActiveButton
                    className="me-3"
                    active={exam.display}
                    onClick={() =>
                      handleUpdate(exam, { display: !exam.display })
                    }
                  />

                  <span
                    className="action"
                    onClick={() => handleImageModal(exam)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-image-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                    </svg>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal isOpen={open} onRequestClose={closeImageModal} style={modalStyles}>
        <h4>{imageExam.code}</h4>

        <div className="my-3">
          {imageExam.image ? (
            <img
              src={imageExam.image}
              alt={imageExam.code}
              className="img-thumbnail"
            />
          ) : (
            "No image"
          )}
        </div>

        <div>
          <Image
            directory={AWS_DIRECTORIES.EXAMS}
            onUpload={handleImageChange}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ViewExams;
