import AddSection from "../pages/AddSection";
import AdminsPage from "../pages/Admin/admins";
import ExamAnalysis from "../pages/Analysis/Analysis";
import CreateExamAnalysis from "../pages/Analysis/CreateAnalysis";
import EditExamAnalysis from "../pages/Analysis/EditAnalysis";
import AddAssignmentPage from "../pages/Assignment/add-assignment";
import EditAssignment from "../pages/Assignment/edit-assignment";
import AssignmnetPage from "../pages/Assignment/index";
import Bulk_page from "../pages/Bulk/bulk";
import CreateContent from "../pages/Content/AddContent";
import ContentBulkEdit from "../pages/Content/BulkContent";
import EditContent from "../pages/Content/EditContent";
import Contents from "../pages/Content/index";
import ViewContent from "../pages/Content/ViewContent";
import CourseChapter from "../pages/Courses/courseChapter";
import CourseTopic from "../pages/Courses/courseTopic";
import Courses from "../pages/Courses/index";
import DepartmentSettings from "../pages/DepartmentSettings";
import AddDppPage from "../pages/Dpp/addDpp";
import EditDpp from "../pages/Dpp/editDpp";
import DppPage from "../pages/Dpp/index";
import Forum from "../pages/Forum";
import ViewForum from "../pages/Forum/ViewForum";
import HomePage from "../pages/Home";
import ImageDeletion from "../pages/ImageDeletion";
import Jobs from "../pages/Jobs";
import AddJob from "../pages/Jobs/add-job";
import CreateNotification from "../pages/Notification/CreateNotification";
import EditNotification from "../pages/Notification/EditNotification";
import Notifications from "../pages/Notification/Notifications";
import PasswordPage from "../pages/password";
import Payment from "../pages/Payment";
import CreatePayment from "../pages/Payment/CreatePayment";
import MakePayment from "../pages/Payment/MakePayment";
import AddPreviousYear from "../pages/PreviousYear/addPreviousYear";
import EditPreviousYear from "../pages/PreviousYear/editPreviousYear";
import PreviousYearsPage from "../pages/PreviousYear/previousYears";
import AddQuestion from "../pages/Questions/AddQuestion";
import ViewPage from "../pages/Questions/DepartmentQuestions";
import QuestionEditPage from "../pages/Questions/EditQuestion";
import SelectQuestionType from "../pages/Questions/SelectQuestionType";
import QuestionViewPage from "../pages/Questions/ViewQuestion";
import EditPayment from "../pages/Payment/EditPayment";
import Referrals from "../pages/Referrals";
import CreateReferralCode from "../pages/Referrals/CreateReferralCode";
import UpdateReferralCode from "../pages/Referrals/UpdateCreateReferralCode";
import Reports from "../pages/Reports";
import Reviews from "../pages/Reviews";
import CreateReview from "../pages/Reviews/CreateReview";
import CreateSyllabus from "../pages/Syllabus/CreateSyllabus";
import SyllabusEditPage from "../pages/Syllabus/EditSyllabus";
import Syllabus from "../pages/Syllabus/Syllabus";
import TestSeriesPage from "../pages/Testseries";
import AddTestSeries from "../pages/Testseries/AddTestseries";
import EditTestSeries from "../pages/Testseries/EditTestseries";
import CreateVideo from "../pages/Video/CreateVideo";
import EditGeneralVideo from "../pages/Video/EditVideos";
import GeneralVideo from "../pages/Video/Videos";
import ViewSections from "../pages/ViewSections";
import WorkbookPage from "../pages/Workbook/";
import WorkbookAddPage from "../pages/Workbook/AddWorkbook";
import EditWorkbook from "../pages/Workbook/EditWorkbook";
import CreateYearCutoff from "../pages/YearCutOff/CreateYearCutoff";
import EditYearCutOff from "../pages/YearCutOff/EditYearCutoff";
import YearCutOff from "../pages/YearCutOff/YearCutoff";

const array = [
  {
    path: "/payment/make-payment",
    page: MakePayment,
  },
  {
    path: "/image-deletion",
    page: ImageDeletion,
  },
  {
    path: "/reports/:department/:exam",
    page: Reports,
  },
  {
    path: "/forum/:department/:exam",
    page: Forum,
  },
  {
    path: "/forum/view",
    page: ViewForum,
  },
  {
    path: "/jobs/:department/:exam",
    page: Jobs,
  },
  {
    path: "/create-job/:department/:exam",
    page: AddJob,
  },
  {
    path: "/create-dpp/:department/:exam",
    page: AddDppPage,
  },
  {
    path: "/edit-dpp/:id/:department",
    page: EditDpp,
  },
  {
    path: "/dpp/:department/:exam",
    page: DppPage,
  },
  {
    path: "/add-assignment/:department/:exam",
    page: AddAssignmentPage,
  },
  {
    path: "/edit-assignment/:id/:department",
    page: EditAssignment,
  },
  {
    path: "/assignmnetPage/:department/:exam",
    page: AssignmnetPage,
  },
  {
    path: "/add-workbook/:department/:exam",
    page: WorkbookAddPage,
  },
  {
    path: "/edit-workbook/:id/:exam",
    page: EditWorkbook,
  },
  {
    path: "/workbook/:department/:exam",
    page: WorkbookPage,
  },
  {
    path: "/content-bulk-edit",
    page: ContentBulkEdit,
  },
  {
    path: "/add-content/:departmentId/:examId",
    page: CreateContent,
  },
  {
    path: "/edit-content/:contentId",
    page: EditContent,
  },
  {
    path: "/view-content/:contentId",
    page: ViewContent,
  },
  {
    path: "/contents/:department",
    page: Contents,
  },
  {
    path: "/add-sections",
    page: AddSection,
  },
  {
    path: "/view-sections",
    page: ViewSections,
  },
  {
    path: "/bulk-edit/:department",
    page: Bulk_page,
  },
  {
    path: "/testSeries/:department/:exam",
    page: TestSeriesPage,
  },
  {
    path: "/add-testSeries/:department/:exam",
    page: AddTestSeries,
  },
  {
    path: "/edit-testSeries/:id/:department",
    page: EditTestSeries,
  },
  {
    path: "/edit-previous-year-paper/:id/:department",
    page: EditPreviousYear,
  },
  {
    path: "/admins",
    page: AdminsPage,
  },
  {
    path: "/previous-year-papers/:department/:exam",
    page: PreviousYearsPage,
  },
  {
    path: "/add-previous-year-paper/:department/:exam",
    page: AddPreviousYear,
  },
  {
    path: "/password",
    page: PasswordPage,
  },
  {
    path: "/new/question",
    page: AddQuestion,
  },
  {
    path: "/question-types",
    page: SelectQuestionType,
  },
  {
    path: "/view/question/:questionId",
    page: QuestionViewPage,
  },
  {
    path: "/questions/:department/:exam",
    page: ViewPage,
  },
  {
    path: "/edit/question",
    page: QuestionEditPage,
  },

  {
    path: "/notifications/:department/:exam",
    page: Notifications,
  },
  {
    path: "/create-notification",
    page: CreateNotification,
  },
  {
    path: "/edit-notification/:id/:department",
    page: EditNotification,
  },
  {
    path: "/syllabus/:department/:exam",
    page: Syllabus,
  },
  {
    path: "/create-syllabus",
    page: CreateSyllabus,
  },
  {
    path: "/edit-syllabus/:id/:department",
    page: SyllabusEditPage,
  },
  {
    path: "/yearCutOff/:department/:exam",
    page: YearCutOff,
  },
  {
    path: "/create-yearCutoff",
    page: CreateYearCutoff,
  },
  {
    path: "/edit-yearCutOff/:id/:department",
    page: EditYearCutOff,
  },
  {
    path: "/examAnalysis/:department/:exam",
    page: ExamAnalysis,
  },
  {
    path: "/create-exam-analysis",
    page: CreateExamAnalysis,
  },
  {
    path: "/edit-examAnalysis/:id/:department",
    page: EditExamAnalysis,
  },

  {
    path: "/generalVideo/:department/:exam",
    page: GeneralVideo,
  },
  {
    path: "/create-video",
    page: CreateVideo,
  },
  {
    path: "/edit-generalVideo/:id/:department",
    page: EditGeneralVideo,
  },

  {
    path: "/reviews/:department/:exam",
    page: Reviews,
  },
  {
    path: "/create-review/:id",
    page: CreateReview,
  },
  {
    path: "/payment/:department/:exam",
    page: Payment,
  },
  {
    path: "/create-payment",
    page: CreatePayment,
  },
  {
    path: "/edit-payment/:paymentId",
    page: EditPayment,
  },
  {
    path: "/course/chapter/:chapterId/:subjectId/:chapterName",
    page: CourseChapter,
  },
  {
    path: "/course/topic/:topicId/:subjectId/:topicName",
    page: CourseTopic,
  },
  {
    path: "/course/:department/:exam",
    page: Courses,
  },
  {
    path: "/department-settings",
    page: DepartmentSettings,
  },
  {
    path: "/referral-codes",
    page: Referrals,
  },
  {
    path: "/create-referral-code",
    page: CreateReferralCode,
  },

  {
    path: "/update-referral-code/:id",
    page: UpdateReferralCode,
  },
  {
    path: "/",
    page: HomePage,
  },
];

export default array;
