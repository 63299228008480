import { useEffect, useState } from "react";
import { AiOutlineBook, AiOutlineVideoCamera } from "react-icons/ai";
import { BiAnalyse } from "react-icons/bi";
import { BsBookHalf, BsPencilFill, BsReceiptCutoff } from "react-icons/bs";
import { GiPapers } from "react-icons/gi";
import { IoMdPaper } from "react-icons/io";
import {
  MdForum,
  MdNotificationsActive,
  MdOutlineRateReview,
  MdPayment,
  MdReportGmailerrorred,
  MdWork,
} from "react-icons/md";
import { SiCoursera, SiShortcut } from "react-icons/si";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/buttons";
import Card from "../../components/Card/card";
import { authUser } from "../../providers/index";
import { Admin } from "../../utilities/constants";

const CardSection = ({ exam, department }) => {
  const auth = useRecoilValue(authUser);
  const [disable, setDisable] = useState(false);
  const { push } = useHistory();

  useEffect(() => {
    setDisable(!exam || !department);
  }, [exam, department]);

  if (auth?.role === Admin.SUPPORT && auth.role === Admin.SENIOR) {
    return;
  }

  return (
    <div className="container">
      <div className="row webkit-center justify-content-center">
        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            disabled={disable}
            onClick={() =>
              push(
                `/testSeries/${department.split("/")[1]}/${exam.split("/")[1]}`
              )
            }
          >
            <Card
              disabled={disable}
              icon={<BsPencilFill />}
              title="Test Series"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            className="dashboard-button"
            transparent
            custom
            width="100"
            disabled={disable}
            onClick={() =>
              push(
                `/previous-year-papers/${department.split("/")[1]}/${
                  exam.split("/")[1]
                }`
              )
            }
          >
            <Card
              disabled={disable}
              icon={<IoMdPaper />}
              title="Previous Years Papers"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            disabled={disable}
            onClick={() =>
              push(`/dpp/${department.split("/")[1]}/${exam.split("/")[1]}`)
            }
          >
            <Card disabled={disable} icon={<BsReceiptCutoff />} title="DPP" />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            disabled={disable}
            onClick={() =>
              push(
                `/assignmnetPage/${department.split("/")[1]}/${
                  exam.split("/")[1]
                }`
              )
            }
          >
            <Card disabled={disable} icon={<GiPapers />} title="Assignments" />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            disabled={disable}
            onClick={() =>
              push(
                `/workbook/${department.split("/")[1]}/${exam.split("/")[1]}`
              )
            }
          >
            <Card disabled={disable} icon={<BsBookHalf />} title="Work Book" />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            disabled={disable}
            onClick={() =>
              push(
                `/syllabus/${department.split("/")[1]}/${exam.split("/")[1]}`
              )
            }
          >
            <Card
              disabled={disable}
              icon={<AiOutlineBook />}
              title="Syllabus"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(
                `/yearCutOff/${department.split("/")[1]}/${exam.split("/")[1]}`
              )
            }
            disabled={disable}
          >
            <Card
              disabled={disable}
              icon={<SiShortcut />}
              title="Previous years cutoffs"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(
                `/examAnalysis/${department.split("/")[1]}/${
                  exam.split("/")[1]
                }`
              )
            }
            disabled={disable}
          >
            <Card
              disabled={disable}
              icon={<BiAnalyse />}
              title="Exam analysis"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(
                `/generalVideo/${department.split("/")[1]}/${
                  exam.split("/")[1]
                }`
              )
            }
            disabled={disable}
          >
            <Card
              disabled={disable}
              icon={<AiOutlineVideoCamera />}
              title="General Videos"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            disabled={disable}
            onClick={() =>
              push(
                `/notifications/${department.split("/")[1]}/${
                  exam.split("/")[1]
                }`
              )
            }
          >
            <Card
              disabled={disable}
              icon={<MdNotificationsActive />}
              title="Notifications"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(`/reviews/${department.split("/")[1]}/${exam.split("/")[1]}`)
            }
            disabled={disable}
          >
            <Card
              disabled={disable}
              icon={<MdOutlineRateReview />}
              title="Department Data"
            />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(`/payment/${department.split("/")[1]}/${exam.split("/")[1]}`)
            }
            disabled={disable}
          >
            <Card disabled={disable} icon={<MdPayment />} title="Payments" />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(`/course/${department.split("/")[1]}/${exam.split("/")[1]}`)
            }
            disabled={disable}
          >
            <Card disabled={disable} icon={<SiCoursera />} title="Courses" />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(`/forum/${department.split("/")[1]}/${exam.split("/")[1]}`)
            }
            disabled={disable}
          >
            <Card disabled={disable} icon={<MdForum />} title="Forum" />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(`/jobs/${department.split("/")[1]}/${exam.split("/")[1]}`)
            }
            disabled={disable}
          >
            <Card disabled={disable} icon={<MdWork />} title="Jobs" />
          </Button>
        </div>

        <div className="col-lg-4 mt-5">
          <Button
            transparent
            className="dashboard-button"
            custom
            width="100"
            onClick={() =>
              push(`/reports/${department.split("/")[1]}/${exam.split("/")[1]}`)
            }
            disabled={disable}
          >
            <Card
              disabled={disable}
              icon={<MdReportGmailerrorred />}
              title="Reports"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
