import classNames from "classnames";
import React, { useState } from "react";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const ImageDeletion = ({ section }) => {
  const [imageLink, setImageLink] = useState("");

  const handleDeleteImage = async () => {
    try {
      const res = await instance.delete(endpoints.deleteFromS3, {
        data: { link: imageLink },
      });

      if (res.status === 200) {
        showToast({
          type: "success",
          message: "Image deleted successfully",
        });

        setImageLink("");
      } else {
        showToast({
          type: "error",
          message: "Failed to delete image",
        });
      }
    } catch (err) {
      showToast({
        type: "error",
        message: "Failed to delete image",
      });
    }
  };

  return (
    <div>
      <h3 className="space-heading text-center">Image Deletion</h3>

      <div className="mt-5">
        <div className={classNames("mx-auto", section ?? "w-50")}>
          <p>
            This {section ? "section" : "page"} is used to delete images from
            the server. You can delete images by entering the image link and
            clicking on the delete button.
          </p>

          <p>
            <strong>Note:</strong> Please be careful while deleting the images.
          </p>

          <div className="mt-5 d-flex gap-2">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Image URL"
              value={imageLink}
              onChange={(e) => setImageLink(e.target.value)}
            />

            <button className="btn btn-danger" onClick={handleDeleteImage}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDeletion;
