import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useUserActions from "../../hooks/useUserActions";
import { authUser, department } from "../../providers/index";
import { Admin } from "../../utilities/constants";

const ProfileOptions = () => {
  const recoilAuth = useRecoilValue(authUser);
  const selectedDepartment = useRecoilValue(department);

  const role = recoilAuth.role;
  return (
    <div className="d-flex text-white flex-column">
      <h6
        style={{
          color: "#cccccc",
          fontSize: 16,
          textTransform: "uppercase",
          textAlign: "center",
          letterSpacing: 2,
          fontWeight: 400,
        }}
      >
        {role} Admin
      </h6>
      <hr
        style={{
          position: "relative",
          marginTop: 0,
          marginBottom: 12,
        }}
      />

      {(role === Admin.MASTER || role === Admin.EXAM) && (
        <Link to="/admins" className="text-decoration-none text-white mb-1">
          View Administrators
        </Link>
      )}

      {selectedDepartment ? (
        <Link
          to="/department-settings"
          className="text-decoration-none text-white mb-1"
        >
          Department Settings
        </Link>
      ) : null}

      {(role === Admin.MASTER || role === Admin.EXAM) && (
        <Link
          to="/referral-codes"
          className="text-decoration-none text-white mb-1"
        >
          Referral Codes
        </Link>
      )}

      {role === Admin.MASTER && (
        <Link
          to="/image-deletion"
          className="text-decoration-none text-white mb-1"
        >
          Image Deletion
        </Link>
      )}

      {role === Admin.MASTER && (
        <Link
          to="/payment/make-payment"
          className="text-decoration-none text-white mb-1"
        >
          Make Payment
        </Link>
      )}

      <Link to="/password" className="text-decoration-none text-white">
        Change Password
      </Link>
    </div>
  );
};

const DropDown = ({ setToggle, toggle }) => {
  const auth = useUserActions();
  return (
    <div className="drop-down card " onClick={() => setToggle(!toggle)}>
      <div className="card-body text">
        <ProfileOptions />
        <h6
          className="text-white mt-2"
          style={{ cursor: "pointer" }}
          onClick={() => auth.logout()}
        >
          Logout
        </h6>
      </div>
    </div>
  );
};

export default DropDown;
