import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Button, Submit } from "../../components/buttons";
import { Input, Select } from "../../components/inputs";
import { SelectQuestionModal } from "../../components/SelectQuestionModal";
import { endpoints } from "../../constants/Endpoints";
import { authUser } from "../../providers/index";
import { instance } from "../../services/https/inceptors";
import { Admin as Role } from "../../utilities/constants";
import { showToast } from "../../utilities/toast";

const Admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const [exams, setExams] = useState([]);
  const [selectedExams, setSelectedExams] = useState([]);
  const [departments, setDepartments] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [admins, setAdmins] = useState([]);
  const [adminStatus, setAdminStatus] = useState(1);
  const [adminEdit, setAdminEdit] = useState({});

  const [show, setShow] = useState(false);

  const [examCheckBoxValues, setExamCheckBoxValues] = useState([]);
  const [departmentCheckBoxValues, setDepartmentCheckBoxValues] = useState([]);

  const sortOrder = ["master", "exam", "department", "senior", "support"];

  const { examPermissions, role: userRole } = useRecoilValue(authUser);

  useEffect(() => {
    const getAllowedExams = async () => {
      const query = `?examPermissions=${examPermissions.toString()}`;
      const res = await instance.get(`${endpoints.getAllowedExams}${query}`);
      setExams(res.data);
    };

    getAllowedExams();
  }, [examPermissions]);

  useEffect(() => {
    const getAllAdmins = async () => {
      const query = `?status=${parseInt(adminStatus) !== 1}`;
      const res = await instance.get(`${endpoints.getAllAdmins}${query}`);
      setAdmins(res.data);
    };

    getAllAdmins();
    console.log();
  }, [adminStatus]);

  function closeDialog() {
    setShow(false);
  }

  const reset = async () => {
    const examPermissions = [];
    const departmentPermissions = [];
    const data = {
      _id: adminEdit._id,
      examPermissions,
      departmentPermissions,
    };

    try {
      const res = await instance.put(endpoints.updateAdmin, data);
      const adminClone = [...admins];
      const index = adminClone.findIndex((admin) => admin._id === res.data._id);

      if (index > -1) {
        setAdmins(adminClone);
        setDepartmentCheckBoxValues({});
        setExamCheckBoxValues({});
        adminClone[index] = {};
      }
      setShow(false);
      showToast({
        type: "success",
        message: "Successfully reset the admin permissions",
      });
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  const removeValue = (list, value, separator) => {
    separator = separator || ",";
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (values[i] === value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  };

  const getExamCode = (examId) => {
    return exams.find((exam) => exam._id === examId).code;
  };

  const onExamChange = async (event) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedExams((prev) => [...prev, value]);

      if (role !== Role.MASTER && role !== Role.EXAM) {
        const res = await instance.get(
          `${endpoints.getDepartmentsByExam}?exam=${value}`
        );

        setDepartments((prev) => {
          return { ...prev, [value]: res.data };
        });
      }
    } else {
      setSelectedExams((prev) => prev.filter((item) => item !== value));

      if (role !== Role.MASTER && role !== Role.EXAM) {
        setDepartments((prev) => {
          const clone = { ...prev };
          delete clone[value];
          return clone;
        });
      }
    }
  };

  const onDepartmentChange = (event) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedDepartments((prev) => [...prev, value]);
    } else {
      setSelectedDepartments((prev) => prev.filter((item) => item !== value));
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!username)
      return showToast({ type: "error", message: "Username is required" });

    if (!password)
      return showToast({ type: "error", message: "Password is required" });

    if (!role) return showToast({ type: "error", message: "Role is required" });

    const data = {
      username,
      password,
      role,
    };

    if (role === Role.EXAM) {
      data.examPermissions = selectedExams;
    } else if (role !== Role.MASTER && role !== Role.EXAM) {
      data.examPermissions = selectedExams;
      data.departmentPermissions = selectedDepartments;
    }

    const endpoint = {
      [Role.MASTER]: endpoints.makeMasterAdmin,
      [Role.EXAM]: endpoints.makeExamAdmin,
      [Role.DEPARTMENT]: endpoints.makeDepartmentAdmin,
      [Role.SENIOR]: endpoints.makeSeniorAdmin,
      [Role.SUPPORT]: endpoints.makeSupportAdmin,
    };

    try {
      const res = await instance.post(endpoint[role], data);
      setAdmins([...admins, res.data.data]);
      setUsername("");
      setPassword("");
      setRole("");
      setSelectedExams([]);
      setSelectedDepartments([]);
      showToast({ type: "success", message: "Successfully added the admin" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const onEdit = (admin) => async () => {
    setSelectedDepartments("");
    const clone = {};
    const clone2 = {};

    let str = "";
    let str2 = "";

    admin.examPermissions.forEach((permission, i) => {
      clone[permission._id] = true;
      if (i === 0) {
        str = str + permission?._id;
      } else {
        str = str + "," + permission?._id;
      }
    });

    admin.departmentPermissions.forEach((permission, i) => {
      clone2[permission._id] = true;
      if (i === 0) {
        str2 = str2 + permission?._id;
      } else {
        str2 = str2 + "," + permission?._id;
      }
    });

    if (
      admin.role !== Role.EXAM &&
      admin.role !== Role.MASTER &&
      admin.examPermissions[0]
    ) {
      setSelectedDepartments(str2);

      const res = await instance.get(
        endpoints.getDepartmentsByExam +
          `?exam=${admin.examPermissions[0]?._id}`
      );
      setDepartments(res.data);
    }

    setDepartmentCheckBoxValues(clone2);
    setSelectedExams(str);
    setExamCheckBoxValues(clone);
    setShow(true);
    setAdminEdit(admin);
  };

  const updatePermission = async (e) => {
    e.preventDefault();
    const body = { _id: adminEdit._id };
    if (selectedExams.length) {
      body.examPermissions = selectedExams.split(",");
    }
    if (selectedDepartments.length) {
      body.departmentPermissions = selectedDepartments.split(",");
    }
    try {
      const res = await instance.put(endpoints.updateAdmin, body);
      const adminClone = [...admins];
      const index = adminClone.findIndex(
        (admin) => admin?._id === res?.data?._id
      );
      if (index > -1 && res.data) {
        adminClone[index] = res.data;
      }
      setAdmins(adminClone);
      showToast({
        type: "success",
        message: "Succssfully Updated the permissions",
      });
    } catch (err) {
      showToast({
        type: "error",
        message: err.response.data,
      });
    } finally {
      setShow(false);
    }
  };

  const actionChange = (admin, data) => async () => {
    const key = Object.keys(data)[0];
    data._id = admin._id;
    let text;

    if (key === "role") {
      text = `Change ${admin.username}'s role to ${data[key]} admin?`;
    } else if (key === "disabled") {
      text = `${data[key] ? "Disable" : "Enable"} ${admin.username}'s account?`;
    } else {
      text = `Reset ${admin.username}'s password?`;
    }

    if (window.confirm(text)) {
      await instance.put(endpoints.updateAdmin, data);
      if (key === "password") {
        return alert(`Success: Password reset to ${data[key]}`);
      }
      admin[key] = data[key];
      setAdmins([...admins]);
    }
  };

  const modalOnExamChange = async (event) => {
    setSelectedDepartments("");
    setDepartmentCheckBoxValues({});
    let temp = "";
    const clone = { ...examCheckBoxValues };
    if (adminEdit.role === Role.EXAM) {
      clone[event.target.name] = event.target.checked; // can tick multiple values
    } else {
      clone[event.target.name] = event.target.checked;
      for (let key in clone) {
        // logic for tick one value at a time
        if (key !== event.target.name) {
          clone[key] = false;
        }
      }
    }
    setExamCheckBoxValues(clone);

    if (event.target.checked && adminEdit.role === Role.EXAM) {
      if (selectedExams.length) {
        setSelectedExams(selectedExams + "," + event.target.value);
        temp = selectedExams + "," + event.target.value;
      } else {
        setSelectedExams(event.target.value);
        temp = event.target.value;
      }
    } else if (!event.target.checked && adminEdit.role === Role.EXAM) {
      const string = removeValue(selectedExams, event.target.value, ",");
      temp = string;
      setSelectedExams(string);
    } else if (event.target.checked) {
      temp = event.target.value;
      setSelectedExams(event.target.value);
    }

    if (adminEdit.role !== Role.EXAM) {
      const res = await instance.get(
        endpoints.getDepartmentsByExam + `?exam=${temp}`
      );
      setDepartments(res.data);
    }
  };

  const onModalDepartmentChange = (event) => {
    const clone = { ...departmentCheckBoxValues };
    clone[event.target.name] = event.target.checked;
    setDepartmentCheckBoxValues(clone);

    if (event.target.checked) {
      if (selectedDepartments.length) {
        setSelectedDepartments(selectedDepartments + "," + event.target.value);
      } else {
        setSelectedDepartments(event.target.value);
      }
    } else if (!event.target.checked) {
      const string = removeValue(selectedDepartments, event.target.value, ",");
      setSelectedDepartments(string);
    } else if (event.target.checked) {
      setSelectedDepartments(event.target.value);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-evenly align-items-center">
        <h5 className="mb-3"> Add Admin</h5>
      </div>

      <form onSubmit={onSubmit}>
        <div className="row row-cols-3 gx-5 gy-3">
          <div className="form-group col">
            <Input
              label="Username"
              placeholder="Username"
              value={username}
              onChange={setUsername}
            />
          </div>

          <div className="form-group col">
            <Input
              label="Password"
              placeholder="Password"
              value={password}
              onChange={setPassword}
            />
          </div>

          <div className="form-group col">
            <Select
              label="Role"
              value={role}
              onChange={(e) => {
                setRole(e);
                setSelectedExams([]);
                setSelectedDepartments([]);
              }}
            >
              {userRole === "master" && (
                <option value={Role.MASTER}>Master admin</option>
              )}
              <option value={Role.EXAM}>Exam admin</option>
              <option value={Role.DEPARTMENT}>Department admin</option>
              <option value={Role.SENIOR}>Senior admin</option>
              <option value={Role.SUPPORT}>Support admin</option>
            </Select>
          </div>
        </div>

        {role && role !== Role.MASTER && (
          <>
            <h5 className="my-3">Exam permissions</h5>

            <div className="row row-cols-4 gx-5 gy-3">
              {exams.map((exam) => {
                return (
                  <div className="col" key={exam._id}>
                    <div className="form-check">
                      <label className="form-check-label">{exam.title}</label>

                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedExams.includes(exam._id)}
                        onChange={onExamChange}
                        value={exam._id}
                        name={exam._id}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {role && role !== Role.MASTER && role !== Role.EXAM && (
          <>
            <h5 className="my-3">Department Permissions</h5>

            {Object.keys(departments).map((examId) => {
              return (
                <div key={examId} className="mb-3">
                  <h6>{getExamCode(examId)} departments</h6>

                  <div className="row row-cols-4 gx-5 gy-3">
                    {departments[examId].map((department) => {
                      return (
                        <div className="col" key={department._id}>
                          <div className="form-check">
                            <label className="form-check-label">
                              {department.title}
                            </label>

                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedDepartments.includes(
                                department._id
                              )}
                              onChange={onDepartmentChange}
                              value={department._id}
                              name={department._id}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}

        <div className="row justify-content-center my-5 row-cols-4">
          <div className="col">
            <Submit />
          </div>
        </div>
      </form>

      <br />

      <div className="table-responsive mt-5">
        <div className="d-flex justify-content-end">
          <Select value={adminStatus} onChange={setAdminStatus} label="Status">
            <option value={1}>Active</option>
            <option value={0}>Disable</option>
          </Select>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">Role</th>
              <th scope="col">Exam Permission</th>
              <th scope="col">Department Permission</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {admins
              .toSorted(
                (a, b) => sortOrder.indexOf(a.role) - sortOrder.indexOf(b.role)
              )
              .map((admin, index) => (
                <tr key={admin._id}>
                  <th scope="row">{index + 1}</th>
                  <td>{admin.username}</td>
                  <td style={{ textTransform: "uppercase" }}>{admin.role}</td>
                  <td>
                    <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                      {admin.examPermissions.map((adm) => {
                        return (
                          <div
                            style={{
                              background: "#79a8ff",
                              padding: "4px 6px",
                              borderRadius: "5px",
                              fontSize: 13,
                            }}
                            key={adm._id}
                          >
                            {adm.title}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
                      {admin.departmentPermissions?.map((dep) => {
                        return (
                          <div
                            style={{
                              background: "#79a8ff",
                              padding: "4px 6px",
                              borderRadius: "5px",
                              fontSize: 13,
                            }}
                            key={dep._id}
                          >
                            {dep.title}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <div className="flex">
                      {admin.role !== Role.SUPPORT && (
                        <>
                          {admin.role === Role.MASTER && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.EXAM,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                          {admin.role === Role.EXAM && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.DEPARTMENT,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                          {admin.role === Role.DEPARTMENT && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.SENIOR,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                          {admin.role === Role.SENIOR && (
                            <button
                              className="action down"
                              onClick={actionChange(admin, {
                                role: Role.SUPPORT,
                              })}
                            >
                              &#x02193;
                            </button>
                          )}
                        </>
                      )}
                      {admin.role !== Role.MASTER && (
                        <>
                          {admin.role === Role.SUPPORT && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.SENIOR,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                          {admin.role === Role.SENIOR && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.DEPARTMENT,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                          {admin.role === Role.DEPARTMENT && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.EXAM,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                          {admin.role === Role.EXAM && (
                            <button
                              className="action up"
                              onClick={actionChange(admin, {
                                role: Role.MASTER,
                              })}
                            >
                              &#x02191;
                            </button>
                          )}
                        </>
                      )}
                      <button
                        className="action back mx-2"
                        onClick={actionChange(admin, {
                          password: admin.username,
                        })}
                      >
                        &#x02190;
                      </button>
                      {admin.disabled && (
                        <button
                          className="action check"
                          onClick={actionChange(admin, { disabled: false })}
                        >
                          &#x02713;
                        </button>
                      )}
                      {!admin.disabled && (
                        <button
                          className="action delete"
                          onClick={actionChange(admin, { disabled: true })}
                        >
                          &#x003A7;
                        </button>
                      )}

                      {admin.role !== Role.MASTER && (
                        <button
                          className="action edit mx-2"
                          onClick={onEdit(admin)}
                        >
                          &curren;
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <SelectQuestionModal
        title="Edit Permissions"
        size="lg"
        show={show}
        onClose={closeDialog}
        footerVisible={false}
      >
        <form onSubmit={updatePermission}>
          {adminEdit.role !== "master" && (
            <>
              <h5 className="mb-3 mt-3">Exam Permission</h5>
              <div className="row row-cols-4 g-3 gx-5">
                {exams.map((exam, index) => {
                  return (
                    <div className="col" key={exam._id}>
                      <div className="form-check">
                        <label
                          htmlFor={"m_checkbox" + (index + 1)}
                          className="form-check-label"
                        >
                          {exam.title}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={examCheckBoxValues[exam?._id]}
                          onChange={modalOnExamChange}
                          value={exam?._id}
                          name={exam?._id}
                          id={"m_checkbox" + (index + 1)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {adminEdit?.role !== "exam" && adminEdit?.role !== "master" && (
            <>
              <h5 className="mb-3 mt-3">Department Permission</h5>
              <div className="row row-cols-4 g-3 gx-5">
                {[].map((department, index) => {
                  return (
                    <div className="col" key={department + index + department}>
                      <div className="form-check">
                        <label
                          htmlFor={"m_dp_checkbox" + (index + 1)}
                          className="form-check-label"
                        >
                          {department?.title}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={onModalDepartmentChange}
                          checked={departmentCheckBoxValues[department._id]}
                          value={department._id}
                          name={department._id}
                          id={"m_dp_checkbox" + (index + 1)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="row justify-content-center my-5 row-cols-4">
            <div className="col">
              <Submit
                disabled={
                  adminEdit?.role === Role.EXAM && !selectedExams
                    ? true
                    : adminEdit?.role !== Role.EXAM &&
                      (!selectedExams || !selectedDepartments)
                    ? true
                    : false
                }
              />
            </div>
            <div className="col">
              <Button width="75" color={"danger"} onClick={reset}>
                Reset
              </Button>
            </div>
          </div>
        </form>
      </SelectQuestionModal>
    </>
  );
};

const AdminsPage = () => (
  <div className="h-100   overflow-auto">
    <div className="container py-5 ">
      <div className="row justify-content-center">
        <Admin />
      </div>
    </div>
  </div>
);
export default AdminsPage;
