import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../components/buttons";
import { Select } from "../../components/inputs";
import { Loader } from "../../components/Loaders";
import { endpoints } from "../../constants/Endpoints";
import useData from "../../hooks/useData";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";
import ForumBox from "./ForumBox";

const Forum = () => {
  const { department } = useParams();

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");

  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");

  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");

  const [recommended, setRecommended] = useState("");
  const [loading, setLoading] = useState(false);
  const [forum, setForum] = useState([]);

  const { getSubjectByDepartment, getChaptersBySubject, getTopicsByChapter } =
    useData();

  const getSubjects = useCallback(async () => {
    const subjects = await getSubjectByDepartment(department);
    setSubjects(subjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  useEffect(() => {
    if (department) getSubjects();
  }, [department, getSubjects]);

  const getChapters = useCallback(async () => {
    const chapter = await getChaptersBySubject(selectedSubject);
    setChapters(chapter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedSubject) getChapters();
  }, [selectedSubject, getChapters]);

  const getTopics = useCallback(async () => {
    const topic = await getTopicsByChapter(selectedChapter);
    setTopics(topic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChapter]);

  useEffect(() => {
    if (selectedChapter) getTopics();
  }, [selectedChapter, getTopics]);

  const selectSubject = async (subject) => {
    setSelectedChapter("");
    setSelectedTopic("");
    setSelectedSubject(subject);
  };

  const selectChapter = async (chapter) => {
    setSelectedTopic("");
    setSelectedChapter(chapter);
  };

  const fetchForum = async () => {
    setLoading(true);

    try {
      const res = await instance.get(
        `${endpoints.getForum}/?department=${department}&subject=${selectedSubject}&chapter=${selectedChapter}&topic=${selectedTopic}&recommended=${recommended}`
      );
      setForum(res.data);
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  const onToggleRecommend = async (update) => {
    try {
      const res = await instance.put(`${endpoints.updateForum}/${update._id}`, {
        recommended: !update.recommended,
      });

      const { data } = res;

      if (data) {
        const clone = [...forum];
        const index = clone.findIndex((f) => f._id === update._id);
        clone[index] = data;
        setForum(clone);

        showToast({
          type: "success",
          message: `Forum ${
            data.recommended ? "unrecommended" : "recommended"
          }`,
        });
      }
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  const onDelete = async (toDelete) => {
    try {
      await instance.delete(`${endpoints.deleteForum}/${toDelete._id}`);
      const clone = forum.filter((f) => f._id !== toDelete._id);
      setForum(clone);
      showToast({ type: "success", message: "Forum Deleted" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="container">
      <div className="row row-cols-4 mt-5">
        <div className="col">
          <Select
            label="Subject"
            value={selectedSubject}
            onChange={selectSubject}
          >
            {subjects.map((sub) => (
              <option key={sub.code} value={sub._id}>
                {sub.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Chapter"
            value={selectedChapter}
            onChange={selectChapter}
          >
            {chapters.map((chapter) => (
              <option key={chapter.code} value={chapter._id}>
                {chapter.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Topic"
            value={selectedTopic}
            onChange={setSelectedTopic}
          >
            {topics.map((topic) => (
              <option key={topic.code} value={topic._id}>
                {topic.title}
              </option>
            ))}
          </Select>
        </div>

        <div className="col">
          <Select
            label="Recommended"
            value={recommended}
            onChange={setRecommended}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Select>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <div></div>

        <Button color="primary" width="10">
          Total: {forum.length}
        </Button>

        <Button
          disabled={loading}
          color="success"
          width="25"
          onClick={fetchForum}
        >
          Go
        </Button>
      </div>

      <hr className="my-5" />

      {loading ? (
        <Loader />
      ) : (
        <div className="mb-5">
          {forum.map((f) => (
            <ForumBox
              forum={f}
              key={f._id}
              onToggleRecommend={onToggleRecommend}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Forum;
