import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Button } from "../../../components/buttons";
import { Input } from "../../../components/inputs";
import { LANGUAGES } from "../../../constants/Common";
import { endpoints } from "../../../constants/Endpoints";
import { instance } from "../../../services/https/inceptors";
import { showToast } from "../../../utilities/toast";

const AddChapterModal = ({ languages, subject, closeModal, setCourse }) => {
  const [chapterTitle, setChapterTitle] = useState({});

  const saveChapters = async () => {
    try {
      const res = await instance.post(endpoints.addCoursesChapter, {
        subjectId: subject,
        chapter: chapterTitle,
      });

      setCourse(res.data.data);
      showToast({ type: "success", message: "Successfully Added Chapter!" });
      closeModal();
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="add-modal-sm">
      <div className="d-flex justify-content-end cross-button">
        <RxCross2 size={24} onClick={closeModal} />
      </div>
      <div className="container mt-3">
        <h3 className="space-heading text-center">Add Chapter</h3>

        <div className="d-flex flex-column gap-1 align-items-center mt-5">
          {languages.map((lang) => {
            const title = Object.keys(LANGUAGES).find(
              (key) => LANGUAGES[key] === lang
            );

            return (
              <Input
                key={lang}
                className="!w-56"
                id="name"
                label={`Chapter Name (${title.toUpperCase()})`}
                value={chapterTitle?.[lang]}
                onChange={(e) =>
                  setChapterTitle((prev) => ({ ...prev, [lang]: e }))
                }
              />
            );
          })}

          <Button onClick={saveChapters} className="mt-4 mb-5">
            Add Chapter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddChapterModal;
