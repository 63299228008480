import { Tabs } from "../../components/layouts";
import ViewChapters from "./ViewChapters";
import ViewDepartments from "./ViewDepartments";
import ViewExams from "./ViewExams";
import ViewSubjects from "./ViewSubjects";
import ViewTopics from "./ViewTopics";

const ViewSections = () => {
  return (
    <Tabs>
      <Tabs.Button id="bExams" target="pExams" selected>
        Exams
      </Tabs.Button>

      <Tabs.Button id="bDepartments" target="pDepartments">
        Departments
      </Tabs.Button>

      <Tabs.Button id="bSubjects" target="pSubjects">
        Subjects
      </Tabs.Button>

      <Tabs.Button id="bChapters" target="pChapters">
        Chapters
      </Tabs.Button>

      <Tabs.Button id="bTopics" target="pTopics">
        Topics
      </Tabs.Button>

      <Tabs.Pane id="pExams" label="bExams" selected>
        <ViewExams />
      </Tabs.Pane>

      <Tabs.Pane id="pDepartments" label="bDepartments">
        <ViewDepartments />
      </Tabs.Pane>

      <Tabs.Pane id="pSubjects" label="bSubjects">
        <ViewSubjects />
      </Tabs.Pane>

      <Tabs.Pane id="pChapters" label="bChapters">
        <ViewChapters />
      </Tabs.Pane>

      <Tabs.Pane id="pTopics" label="bTopics">
        <ViewTopics />
      </Tabs.Pane>
    </Tabs>
  );
};

export default ViewSections;
