import { useEffect, useState } from "react";
import { ActiveButton, EditButton } from "../../components/buttons";
import useData from "../../hooks/useData";
import { BASE, useFetch } from "../../utilities/apis";

const ViewSubjects = () => {
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedSubject, setEditedSubject] = useState({});
  const { getExams, getDepartmentByExam, getSubjectByDepartment } = useData();
  const { update } = useFetch();

  useEffect(() => {
    const fetchExams = async () => {
      const exams = await getExams();
      if (exams) setExams(exams);
    };

    fetchExams();
  }, []);

  useEffect(() => {
    if (selectedExam) {
      const fetchDepartments = async () => {
        const departments = await getDepartmentByExam(selectedExam);
        if (departments) setDepartments(departments);
      };

      fetchDepartments();
    }
  }, [selectedExam]);

  useEffect(() => {
    if (selectedExam && selectedDepartment) {
      const fetchSubjects = async () => {
        const subjects = await getSubjectByDepartment(selectedDepartment);
        if (subjects) setSubjects(subjects);
      };

      fetchSubjects();
    }
  }, [selectedExam, selectedDepartment]);

  const handleEditClick = (index, subject) => {
    if (editingIndex === null) {
      setEditingIndex(index);
      setEditedSubject({
        code: subject.code,
        title: subject.title,
        yearRange: subject.yearRange || {},
      });
    } else if (editingIndex === index) {
      handleUpdate(subject, editedSubject, true);
    } else {
      setEditingIndex(index);
      setEditedSubject({
        code: subject.code,
        title: subject.title,
        yearRange: subject.yearRange || {},
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedSubject((prev) => {
      const updatedSubject = { ...prev };
      const keys = name.split(".");
      let temp = updatedSubject;
      for (let i = 0; i < keys.length - 1; i++) {
        temp = temp[keys[i]] = temp[keys[i]] || {};
      }
      temp[keys[keys.length - 1]] = value;
      return updatedSubject;
    });
  };

  const handleUpdate = (exam, data, edit = false) => {
    update(`${BASE}update/subject/?id=${exam._id}`, data, (error, res) => {
      if (error) return console.log(error);
      const clone = [...subjects];
      const index = clone.findIndex((e) => e._id === exam._id);
      clone[index] = res;
      setSubjects(clone);

      if (edit) {
        setEditingIndex(null);
        setEditedSubject({});
      }
    });
  };

  return (
    <div>
      <form>
        <div className="mb-3">
          <label htmlFor="exam" className="form-label">
            Select exam
          </label>
          <select
            className="form-select"
            id="exam"
            value={selectedExam}
            onChange={(e) => setSelectedExam(e.target.value)}
          >
            <option value="">Select exam</option>
            {exams.map((exam, index) => {
              return (
                <option key={index} value={exam._id}>
                  {exam.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="department" className="form-label">
            Select department
          </label>
          <select
            className="form-select"
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Select department</option>
            {departments.map((department, index) => {
              return (
                <option key={index} value={department._id}>
                  {department.title}
                </option>
              );
            })}
          </select>
        </div>
      </form>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Subject code</th>
            <th scope="col">Subject title</th>
            <th scope="col">Year range</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map((subject, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="code"
                      className="table-control"
                      value={editedSubject.code}
                      onChange={handleInputChange}
                    />
                  ) : (
                    subject.code
                  )}
                </td>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="title"
                      className="table-control"
                      value={editedSubject.title}
                      onChange={handleInputChange}
                    />
                  ) : (
                    subject.title
                  )}
                </td>

                <td>
                  {editingIndex === index ? (
                    <div className="d-flex items-center gap-2">
                      <input
                        type="text"
                        name="yearRange.from"
                        className="table-control"
                        style={{ width: "100px" }}
                        value={
                          editedSubject.yearRange
                            ? editedSubject.yearRange.from
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                      -
                      <input
                        type="text"
                        name="yearRange.to"
                        className="table-control"
                        style={{ width: "100px" }}
                        value={
                          editedSubject.yearRange
                            ? editedSubject.yearRange.to
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    <>
                      {subject.yearRange && (
                        <>
                          {subject.yearRange.from} - {subject.yearRange.to}
                        </>
                      )}
                    </>
                  )}
                </td>

                <td>
                  <EditButton
                    className="me-3"
                    editing={editingIndex === index}
                    onClick={() => handleEditClick(index, subject)}
                  />

                  <ActiveButton
                    active={subject.display}
                    onClick={() =>
                      handleUpdate(subject, { display: !subject.display })
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewSubjects;
