import { useEffect, useState } from "react";
import { ActiveButton, EditButton } from "../../components/buttons";
import useData from "../../hooks/useData";
import { BASE, useFetch } from "../../utilities/apis";

const ViewDepartments = () => {
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");
  const [departments, setDepartments] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedDepartment, setEditedDepartment] = useState({});
  const { getExams, getDepartmentByExam } = useData();
  const { update } = useFetch();

  useEffect(() => {
    const fetchExams = async () => {
      const exams = await getExams();
      if (exams) setExams(exams);
    };

    fetchExams();
  }, []);

  useEffect(() => {
    if (selectedExam) {
      const fetchDepartments = async () => {
        const departments = await getDepartmentByExam(selectedExam);
        if (departments) setDepartments(departments);
      };

      fetchDepartments();
    }
  }, [selectedExam]);

  const handleEditClick = (index, department) => {
    if (editingIndex === null) {
      setEditingIndex(index);
      setEditedDepartment({ code: department.code, title: department.title });
    } else if (editingIndex === index) {
      handleUpdate(department, editedDepartment, true);
    } else {
      setEditingIndex(index);
      setEditedDepartment({ code: department.code, title: department.title });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDepartment({ ...editedDepartment, [name]: value });
  };

  const handleUpdate = (department, data, edit = false) => {
    update(
      `${BASE}update/department/?id=${department._id}`,
      data,
      (error, res) => {
        if (error) return console.log(error);
        const clone = [...departments];
        const index = clone.findIndex((e) => e._id === department._id);
        clone[index] = res;
        setDepartments(clone);

        if (edit) {
          setEditingIndex(null);
          setEditedDepartment({});
        }
      }
    );
  };

  return (
    <div>
      <form>
        <div className="mb-3">
          <label htmlFor="exam" className="form-label">
            Select exam
          </label>
          <select
            className="form-select"
            id="exam"
            value={selectedExam}
            onChange={(e) => setSelectedExam(e.target.value)}
          >
            <option value="">Select exam</option>
            {exams.map((exam, index) => {
              return (
                <option key={index} value={exam._id}>
                  {exam.title}
                </option>
              );
            })}
          </select>
        </div>
      </form>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Department code</th>
            <th scope="col">Department title</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {departments.map((department, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="code"
                      className="table-control"
                      value={editedDepartment.code}
                      onChange={handleInputChange}
                    />
                  ) : (
                    department.code
                  )}
                </td>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="title"
                      className="table-control"
                      value={editedDepartment.title}
                      onChange={handleInputChange}
                    />
                  ) : (
                    department.title
                  )}
                </td>

                <td>
                  <EditButton
                    className="me-3"
                    editing={editingIndex === index}
                    onClick={() => handleEditClick(index, department)}
                  />

                  <ActiveButton
                    active={department.display}
                    onClick={() =>
                      handleUpdate(department, { display: !department.display })
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewDepartments;
