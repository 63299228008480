export const endpoints = {
  login: "/admins/login",

  referralCode: "/referralCode",

  // get
  getExams: "/exams",
  getDepartment: "/departments",
  updateDepartmentLanguages: "/departments/update-languages",
  getAllowedExams: "/exams/allowedExam",
  getQuestions: "/questions/getQuestionsByFilter",
  getSingleQuestion: "/questions",
  getSubjectsByDepartment: "/subjects/byDepartment",
  getChaptersBySubject: "/chapters/bySubject",
  getTopicsByChapter: "/topics/byChapter",
  getTopicsBySubject: "/topics/bySubject",
  getContentByFilter: "/content/byFilter",
  getDepartmentsByExam: "/departments/byExam",
  getAllowedDepartments: "departments/allowedDepartments",
  getAllPreviousPapers: "/previousYears/all",
  getSingleTestSeries: "/testSeries",
  getAllTestSeries: "/testSeries/all",
  getAllDpp: "/dpp/get-all",
  getSingleDpp: "/dpp",
  getAllTopics: "/topics/all",
  getSingleAssignment: "/assignment",
  getAllAssignemnt: "/assignment/get-all",
  getAssignemntBySearch: "/assignment/search",
  getSingleWorkbook: "/workbook",
  getAllWorkbooks: "/workbook/get-all",
  getWorkbookBySearch: "/workbook/search",
  getPreviousYearPaper: "/previousYears",
  getAllAdmins: "/admins/getAdmins",
  getAllNotifications: "/notifications/get-all",
  getSingleNotification: "/notifications",
  getSupportAdmins: "/admins/getSupportAdmins",
  getAllSyllabus: "/syllabus/get-all",
  getSingleSyllabus: "/syllabus",
  getAllYearCutOff: "/yearcutoff/get-all",
  getSingleYearCutOff: "/yearcutoff",
  getAllExamAnalyis: "/exam-analysis/get-all",
  getSingleExamAnalyis: "/exam-analysis",
  getAllVideos: "/general-video/get-all",
  getSingleVideos: "/general-video",

  getReview: "reviews/get",
  
  getAllPayments: "/payment/get-all",

  getSingleContent: "/content/byId",

  getCourseBySubject: "/courses/getCourseBySubject",
  getCourseChaptersData: "/courses/chapterData",
  getCourseTopicData: "/courses/topicData",

  // delete

  deleteQuestion: "/questions/delete",
  deleteBulkQuestions: "/questions/bulk-delete",
  deleteTestSeries: "/testSeries/delete",
  deletePreviousPaper: "/previousYears/delete",
  deleteDpp: "/dpp/delete",
  deleteAssignment: "/assignment/delete",
  deleteWorkbook: "/workbook/delete",
  deleteNotification: "/notifications/delete",
  deleteSyllabus: "/syllabus/delete",
  deleteYearCutOff: "/yearcutoff/delete",
  deleteExamAnalyis: "/exam-analysis/delete",

  deleteVideo: "/general-video/delete",
  deleteReview: "/reviews/delete-review",
  deletePayment: "/payment/delete",
  deleteContent: "/content/delete",

  deleteBulkContent: "/content/bulk-delete",
  deleteCourseChapter: "/courses/delete-chapter",
  deleteCourseWorkBook: "/courses/delete-workBook",
  deleteCourseTest: "/courses/delete-test",
  deleteCourseTopic: "/courses/chapter/delete-topic",
  deleteCourseAssignment: "/courses/chapter/delete-assignment",
  deleteCourseContent: "/courses/topic/delete-content",
  deleteCourseDPP: "/courses/topic/delete-dpp",

  // update

  togglePublish: "/questions/publish_unpublish",
  toggleQuestionReview: "/questions/toggleReview",
  bulkPublishUnpublish: "/questions/bulk-publish-unpublish",
  updateQuestion: "/questions/update",
  updateAdmin: "/admins/update",
  updatePreviousPaper: "/previousYears/update",
  updateTestSeries: "/testSeries/update",
  changePassword: "admins/change-password",
  updateSubject: "/subjects/update",
  updateDepartment: "/departments/update",
  toggleDppDisplay: "/dpp/toggleDisplay",
  toggleDppLock: "/dpp/toggleLock",
  toggleDppPublish: "/dpp/togglePublish",
  updateDpp: "/dpp/update",
  updateAssignment: "/assignment/update",
  toggleAssignmentPublish: "/assignment/togglePublish",
  toggleAssignmentLock: "/assignment/toggleLock",
  toggleAssignmentDisplay: "/assignment/toggleDisplay",
  updateWorkbook: "/workbook/update",
  toggleWorkbookPublish: "/workbook/togglePublish",
  toggleWorkbookLock: "/workbook/toggleLock",
  toggleWorkbookDisplay: "/workbook/toggleDisplay",
  updateNotification: "/notifications/update",
  toggleNotificationPublish: "/notifications/togglePublish",
  toggleNotificationDisplay: "/notifications/toggleDisplay",
  updateSyllabus: "/syllabus/update",
  toggleSyllabusPublish: "/Syllabus/togglePublish",
  toggleSyllabusDisplay: "/Syllabus/toggleDisplay",
  updateYearCutOff: "/yearcutoff/update",
  toggleYearCutOffPublish: "/yearcutoff/togglePublish",
  toggleYearCutOffDisplay: "/yearcutoff/toggleDisplay",
  updateExamAnalyis: "/exam-analysis/update",
  toggleExamAnalyisPublish: "/exam-analysis/togglePublish",
  toggleExamAnalyisDisplay: "/exam-analysis/toggleDisplay",

  updateVideo: "/general-video/update",
  toggleVideoPublish: "/general-video/togglePublish",
  toggleVideoDisplay: "/general-video/toggleDisplay",

  toggleContentReview: "/content/toggleReview",
  toggleContentPublish: "/content/togglePublish",
  editContent: "/content/update",

  toggleBulkContentPublish: "/content/bulk-publish-unpublish",
  toggleBulkContentReview: "/content/bulk-review-unreview",

  // post
  addQuestion: "/questions/addQuestion",
  createContent: "/content/create",
  addPreviousYearsPaper: "/previousYears/new",
  addTestSeries: "/testSeries/new",
  uploadImage: "images/upload",
  addDpp: "/dpp/add-dpp",
  addAssignemnt: "/assignment/add",
  addWorkbook: "/workbook/add",
  addNotification: "/notifications/add",
  addSyllabus: "/syllabus/add",
  addYearCutOff: "/yearcutoff/add",
  addExamAnalysis: "/exam-analysis/add",
  addVideo: "/general-video/add",
  uploadToS3: "/s3/upload-image",

  addReviewTitle: "/reviews/add-title",
  addReviewDescription: "/reviews/add-description",
  addTestSeriesSchedule: "/reviews/add-testSeries-schedule",
  addFeature: "/reviews/add-feature",
  addReview: "/reviews/add-review",
  PAYMENT: "/payment",

  deleteFromS3: "/s3/delete-image",
  addReviewTitle: "/reviews/add-title",
  addReviewDescription: "/reviews/add-description",
  addReview: "/reviews/add-review",
  addPayment: "/payment/add",

  addCoursesChapter: "/courses/add-chapters",
  addCoursesWorkBook: "/courses/add-workbook",
  addCoursesTest: "/courses/add-test",
  addCourseChapterData: "/courses/chapter/add-topics",
  addCourseAssignment: "/courses/chapter/add-assignment",
  addCourseContent: "/courses/topic/add-content",
  addCourseDPP: "/courses/topic/add-dpp",
  addCourseVideoLink: "/courses/topic/add-video-link",

  editCoursesChapter: "/courses/edit-chapter",
  editCoursesChapterData: "/courses/chapter/edit-topics",

  // makeAdmins
  makeMasterAdmin: "admins/make-master-admin",
  makeExamAdmin: "admins/make-exam-admin",
  makeDepartmentAdmin: "admins/make-department-admin",
  makeSeniorAdmin: "admins/make-senior-admin",
  makeSupportAdmin: "admins/make-support-admin",
  
  // forum
  getForum: "/forums-admin/all",
  updateForum: "/forums-admin/update",
  deleteForum: "/forums-admin/delete",
  getForumAnswers: "/forums-admin/answers",
  deleteForumAnswer: "/forums-admin/answers/delete",

  // job
  addJob: "/jobs/new",
  getJobs: "/jobs/all",
  updateJob: "/jobs/update",

  // reports
  getReports: "/reports/all",
  toggleReportResolved: "/reports/toggleResolved",
  deleteReport: "/reports/delete",

  makePayment: "/payment/make-payment",
};
