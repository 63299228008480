import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/buttons";

const ForumBox = ({ forum, onToggleRecommend, onDelete }) => {
  const { push } = useHistory();

  return (
    <div className={classnames("border rounded p-3 mb-3")}>
      <div className="flex">
        <div className="flex-1">
          <div className="mb-4">
            <p>{forum.text}</p>

            <p className="text-sm text-gray-500">
              Posted on: {new Date(forum.createdAt).toLocaleString()}
            </p>
          </div>

          {forum.postedBy.name && (
            <div className="text-sm">
              <strong>Posted by:</strong> {forum.postedBy.name}
            </div>
          )}
        </div>

        <div style={{ width: "150px" }} className="flex flex-col gap-3">
          <Button
            className="w-100 text-sm"
            onClick={() =>
              push("/forum/view", {
                forum,
              })
            }
          >
            View
          </Button>

          <Button
            color="warning"
            className="w-100 text-sm"
            style={{ background: "yellow" }}
            onClick={() => onToggleRecommend(forum)}
          >
            {forum.recommended ? "Unrecommend" : "Recommend"}
          </Button>

          <Button
            color="danger"
            className="w-100 text-sm"
            style={{ background: "red" }}
            onClick={() => onDelete(forum)}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForumBox;
