import { useEffect, useState } from "react";
import { ActiveButton, EditButton } from "../../components/buttons";
import useData from "../../hooks/useData";
import { BASE, useFetch } from "../../utilities/apis";

const ViewTopics = () => {
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [topics, setTopics] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedTopic, setEditedTopic] = useState({});
  const { update } = useFetch();
  const {
    getExams,
    getDepartmentByExam,
    getSubjectByDepartment,
    getChaptersBySubject,
    getTopicsByChapter,
  } = useData();

  useEffect(() => {
    const fetchExams = async () => {
      const exams = await getExams();
      if (exams) setExams(exams);
    };

    fetchExams();
  }, []);

  useEffect(() => {
    if (selectedExam) {
      const fetchDepartments = async () => {
        const departments = await getDepartmentByExam(selectedExam);
        if (departments) setDepartments(departments);
      };

      fetchDepartments();
    }
  }, [selectedExam]);

  useEffect(() => {
    if (selectedExam && selectedDepartment) {
      const fetchSubjects = async () => {
        const subjects = await getSubjectByDepartment(selectedDepartment);
        if (subjects) setSubjects(subjects);
      };

      fetchSubjects();
    }
  }, [selectedExam, selectedDepartment]);

  useEffect(() => {
    if (selectedExam && selectedDepartment && selectedSubject) {
      const fetchChapters = async () => {
        const chapters = await getChaptersBySubject(selectedSubject);
        if (chapters) setChapters(chapters);
      };

      fetchChapters();
    }
  }, [selectedExam, selectedDepartment, selectedSubject]);

  useEffect(() => {
    if (
      selectedExam &&
      selectedDepartment &&
      selectedSubject &&
      selectedChapter
    ) {
      const fetchTopics = async () => {
        const topics = await getTopicsByChapter(selectedChapter);
        if (topics) setTopics(topics);
      };

      fetchTopics();
    }
  }, [selectedExam, selectedDepartment, selectedSubject, selectedChapter]);

  const handleEditClick = (index, topic) => {
    if (editingIndex === null) {
      setEditingIndex(index);
      setEditedTopic({ code: topic.code, title: topic.title });
    } else if (editingIndex === index) {
      handleUpdate(topic, editedTopic, true);
    } else {
      setEditingIndex(index);
      setEditedTopic({ code: topic.code, title: topic.title });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTopic({ ...editedTopic, [name]: value });
  };

  const handleUpdate = (topic, data, edit = false) => {
    update(`${BASE}update/topic/?id=${topic._id}`, data, (error, res) => {
      if (error) return console.log(error);
      const clone = [...topics];
      const index = clone.findIndex((e) => e._id === topic._id);
      clone[index] = res;
      setTopics(clone);

      if (edit) {
        setEditingIndex(null);
        setEditedTopic({});
      }
    });
  };

  return (
    <div>
      <form>
        <div className="mb-3">
          <label htmlFor="exam" className="form-label">
            Select exam
          </label>
          <select
            className="form-select"
            id="exam"
            value={selectedExam}
            onChange={(e) => setSelectedExam(e.target.value)}
          >
            <option value="">Select exam</option>
            {exams.map((exam, index) => {
              return (
                <option key={index} value={exam._id}>
                  {exam.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="department" className="form-label">
            Select department
          </label>
          <select
            className="form-select"
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Select department</option>
            {departments.map((department, index) => {
              return (
                <option key={index} value={department._id}>
                  {department.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            Select subject
          </label>
          <select
            className="form-select"
            id="subject"
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            <option value="">Select subject</option>
            {subjects.map((subject, index) => {
              return (
                <option key={index} value={subject._id}>
                  {subject.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="chapter" className="form-label">
            Select chapter
          </label>
          <select
            className="form-select"
            id="chapter"
            value={selectedChapter}
            onChange={(e) => setSelectedChapter(e.target.value)}
          >
            <option value="">Select chapter</option>
            {chapters.map((chapter, index) => {
              return (
                <option key={index} value={chapter._id}>
                  {chapter.title}
                </option>
              );
            })}
          </select>
        </div>
      </form>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Topic code</th>
            <th scope="col">Topic title</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {topics.map((topic, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="code"
                      className="table-control"
                      value={editedTopic.code}
                      onChange={handleInputChange}
                    />
                  ) : (
                    topic.code
                  )}
                </td>

                <td>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      name="title"
                      className="table-control"
                      value={editedTopic.title}
                      onChange={handleInputChange}
                    />
                  ) : (
                    topic.title
                  )}
                </td>

                <td>
                  <EditButton
                    className="me-3"
                    editing={editingIndex === index}
                    onClick={() => handleEditClick(index, topic)}
                  />

                  <ActiveButton
                    active={topic.display}
                    onClick={() =>
                      handleUpdate(topic, { display: !topic.display })
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewTopics;
