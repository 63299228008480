import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../constants/Endpoints";
import { instance } from "../../services/https/inceptors";
import { showToast } from "../../utilities/toast";

const Jobs = () => {
  const { department, exam } = useParams();

  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState("pending");

  useEffect(() => {
    const getJobs = async () => {
      const res = await instance.get(
        `${endpoints.getJobs}?department=${department}&exam=${exam}&status=${status}`
      );

      setJobs(res.data);
    };

    getJobs();
  }, [status, department, exam]);

  const changeStatus = async (job, newStatus) => {
    try {
      const res = await instance.put(`${endpoints.updateJob}/${job._id}`, {
        status: newStatus,
      });

      const updatedJob = res.data;

      if (updatedJob.status !== status) {
        setJobs((prevJobs) => {
          return prevJobs.filter((j) => j._id !== updatedJob._id);
        });
      } else {
        setJobs((prevJobs) => {
          const clone = [...prevJobs];
          const index = clone.findIndex((j) => j._id === updatedJob._id);
          clone[index] = updatedJob;
          return clone;
        });
      }

      showToast({ type: "success", message: "Successfully updated job" });
    } catch (err) {
      showToast({ type: "error", message: err.response.data.message });
    }
  };

  return (
    <div className="container mb-5">
      <h2 className="text-center mb-5 space-heading">Job Applications</h2>

      <div className="flex justify-end items-end gap-4 mb-4">
        <span className="px-3 py-2 text-white rounded-3xl bg-emerald-600">
          {jobs.length} {jobs.length === 1 ? "job" : "jobs"}
        </span>

        <div className="w-25">
          <select
            id="status"
            className="form-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="accepted">Accepted</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      </div>

      {jobs.map((job) => (
        <div
          key={job._id}
          className="flex justify-between items-center p-3 rounded mb-3 border"
        >
          <div>
            <h6 className="mb-3">{job.role}</h6>

            <div className="grid grid-cols-2">
              <span className="font-bold">Name:</span>
              <span>{job.name}</span>

              <span className="font-bold">Email:</span>
              <span>{job.email}</span>

              <span className="font-bold">Phone:</span>
              <span>{job.number}</span>

              <span className="font-bold">Applied On:</span>
              <span>{new Date(job.createdAt).toLocaleDateString()}</span>
            </div>

            <div className="mt-3">
              <a
                href={job.resume}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                View Resume
              </a>
            </div>
          </div>

          <div>
            <div className="flex gap-3">
              <span className="font-bold">Status:</span>
              <span className="capitalize">{job.status}</span>
            </div>

            {job.status === "pending" && (
              <div className="flex flex-col gap-3 mt-3">
                <button
                  className="btn btn-success"
                  onClick={() => changeStatus(job, "accepted")}
                >
                  Accept
                </button>

                <button
                  className="btn btn-danger"
                  onClick={() => changeStatus(job, "rejected")}
                >
                  Reject
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Jobs;
